import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {
  public navItems = [];
  isuserset: string;
  userID: any;
  email1: string;
  role: string;
  changeform: FormGroup;
  success_message: String;
  error_message: String;
  dataLoading: boolean;
  menu_list: any = [];
  homeHeaderData = { 'userName': null, 'menu_list': null };

  constructor(private userService: UserService, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private titleService: Title) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email1 = userdata['email1'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.menu_list = userdata['menu_list'];
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })
  }

  ngOnInit() {
    this.titleService.setTitle('Change Password - ' + environment.siteTitle);
    this.createForm()
  }

  createForm() {
    this.changeform = new FormGroup({
      "oldPassword": new FormControl("", [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20),
        Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&,.*-]).{6,20}$')
      ]),
      "password": new FormControl("", [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20),
        Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&,.*-]).{6,20}$')
      ]),
      "confirmPassword": new FormControl("", [Validators.required, this.compareValidator('password')]),
    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  compareValidator(controlNameToCompare: string):
    ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (c.value === null || c.value.length === 0) {
        return null;
      }
      const controlToCompare = c.root.get(controlNameToCompare);
      if (controlToCompare) {
        const subscription: Subscription = controlToCompare.valueChanges.subscribe(() => {
          c.updateValueAndValidity(); subscription.unsubscribe();
        });
      }
      return controlToCompare && controlToCompare.value !== c.value ? {
        'compare': true
      } : null;
    };
  }

  changePassword() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    this.changeform.addControl('id', new FormControl(this.userID, Validators.required));
    if (this.changeform.valid) {
      this.apiService.postData('admin/passwordupdate', this.changeform.value)
        .subscribe(res => {
          if (res) {
            this.changeform.reset();
            this.success_message = "Password updated successfully.";
            setTimeout(() => {
              this.router.navigate(['/dashboard']);
            }, 1500);
          } else {
            this.error_message = "Incorrect old password";
            this.dataLoading = false;
          }
        }, (err) => {
          if (err.status == 401) {
            this.router.navigate(['/login']);
          }
          this.error_message = "Something went wrong. please try again.";
          this.dataLoading = false;
        });
    } else {
      this.validateAllFormFields(this.changeform);
      this.dataLoading = false;
    }
  }
}
