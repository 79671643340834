import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { RoleGuardService } from './role-guard.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './footer/footer.component';
import { ManageComponent } from './manage/manage.component';
import { LoginComponent } from './login/login.component';
import { CreateAdminComponent } from './create-admin/create-admin.component';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth-guard.service';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ManageRolesComponent } from './manage-roles/manage-roles.component';
import { CreateRolesComponent } from './create-roles/create-roles.component';
import { EditRolesComponent } from './edit-roles/edit-roles.component';
import { SettingsComponent } from './settings/settings.component';
import { ManageMailsComponent } from './manage-mails/manage-mails.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { ManangeEventComponent } from './manange-event/manange-event.component';
import { CreateEventComponent } from './create-event/create-event.component';
import { EditEventComponent } from './edit-event/edit-event.component';
import { ManageGenreComponent } from './manage-genre/manage-genre.component';
import { CreateGenreComponent } from './create-genre/create-genre.component';
import { EditGenreComponent } from './edit-genre/edit-genre.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { ThemesComponent } from './themes/themes.component';
import { ThemeaddeditComponent } from './themeaddedit/themeaddedit.component';
import { TonesComponent } from './tones/tones.component';
import { ToneaddeditComponent } from './toneaddedit/toneaddedit.component';
import { SceneBuilderComponent } from './scene-builder/scene-builder.component';
import { AddSceneBuilderComponent } from './add-scene-builder/add-scene-builder.component';
import { SceneBuilderDescriptionComponent } from './scene-builder-description/scene-builder-description.component';
import { MovementComponent } from './movement/movement.component';
import { AddEditMovementComponent } from './add-edit-movement/add-edit-movement.component';
import { AddEditThemeQuestionsComponent } from './add-edit-theme-questions/add-edit-theme-questions.component';
import { ThemeQuestionsComponent } from './theme-questions/theme-questions.component';
import { LoglineQuestionComponent } from './logline-question/logline-question.component';
import { AddEditLoglineQuestionComponent } from './add-edit-logline-question/add-edit-logline-question.component';
import { TraitComponent } from './trait/trait.component';
import { AddEditTraitComponent } from './add-edit-trait/add-edit-trait.component';
import { TonesQuestionsComponent } from './tones-questions/tones-questions.component';
import { AddEditToneQuestionsComponent } from './add-edit-tone-questions/add-edit-tone-questions.component';
import { WorldComponent } from './world/world.component';
import { AddEditWorldComponent } from './add-edit-world/add-edit-world.component';
import { CharacterCategoryComponent } from './character-category/character-category.component';
import { AddEditCharacterCategoryComponent } from './add-edit-character-category/add-edit-character-category.component';
import { ManageStoriesComponent } from './manage-stories/manage-stories.component';
import { StoryDetailComponent } from './story-detail/story-detail.component';
import { WorldsAspectsComponent } from './worlds-aspects/worlds-aspects.component';
import { AddEditWorldAspectsComponent } from './add-edit-world-aspects/add-edit-world-aspects.component';
import { WorldsSegmentsComponent } from './worlds-segments/worlds-segments.component';
import { AddEditWorldSegmentsComponent } from './add-edit-world-segments/add-edit-world-segments.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { AddEditTutorialComponent } from './add-edit-tutorial/add-edit-tutorial.component';
import { ManageMediaComponent } from './manage-media/manage-media.component';

import { EditMediaComponent } from './edit-media/edit-media.component';
import { CreateMediaComponent } from './create-media/create-media.component';
import { ThemeOptionsComponent } from './theme-options/theme-options.component';
import { EditThemeOptionComponent } from './edit-theme-option/edit-theme-option.component';
import { AddThemeOptionComponent } from './add-theme-option/add-theme-option.component';

import { CharacterPropertiesComponent } from './character-properties/character-properties.component';
import { AddEditCharacterPropertiesComponent } from './add-edit-character-properties/add-edit-character-properties.component';
import { ProjectPropertiesComponent } from './project-properties/project-properties.component';
import { AddEditProjectPropertiesComponent } from './add-edit-project-properties/add-edit-project-properties.component';
import { ToneOptionComponent } from './tone-option/tone-option.component';
import { AddToneOptionComponent } from './add-tone-option/add-tone-option.component';
import { EditToneOptionComponent } from './edit-tone-option/edit-tone-option.component';
import { WorldSegmentOptionsComponent } from './world-segment-options/world-segment-options.component';
import { AddEditWorldSegmentOptionsComponent } from './add-edit-world-segment-options/add-edit-world-segment-options.component';
import { AddEditProjectGeneralSettingsComponent } from './add-edit-project-general-settings/add-edit-project-general-settings.component';
import { ProjectGeneralSettingsComponent } from './project-general-settings/project-general-settings.component';
import { ManageSidemenuComponent } from './manage-sidemenu/manage-sidemenu.component';
import { EditSidemenuComponent } from './edit-sidemenu/edit-sidemenu.component';
import { ManageArchTypesComponent } from './manage-arch-types/manage-arch-types.component';
import { CreateArchetypeComponent } from './create-archetype/create-archetype.component';
import { EditArchetypeComponent } from './edit-archetype/edit-archetype.component';
import { LoglineDescriptionComponent } from './logline-description/logline-description.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { AddEditGlossaryComponent } from './add-edit-glossary/add-edit-glossary.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { PaymentHistoryDetailComponent } from './payment-history-detail/payment-history-detail.component';
import { SetupPayoffDescriptionComponent } from './setup-payoff-description/setup-payoff-description.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    FooterComponent,
    ManageComponent,
    LoginComponent,
    CreateAdminComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    AdminProfileComponent,
    ChangePasswordComponent,
    ManageRolesComponent,
    CreateRolesComponent,
    EditRolesComponent,
    SettingsComponent,
    ManageMailsComponent,
    ManangeEventComponent,
    CreateEventComponent,
    EditEventComponent,
    ManageGenreComponent,
    CreateGenreComponent,
    EditGenreComponent,
    UserManagementComponent,
    UserDetailComponent,
    ThemesComponent,
    ThemeaddeditComponent,
    TonesComponent,
    ToneaddeditComponent,
    SceneBuilderComponent,
    AddSceneBuilderComponent,
    SceneBuilderDescriptionComponent,
    MovementComponent,
    AddEditMovementComponent,
    AddEditThemeQuestionsComponent,
    ThemeQuestionsComponent,
    LoglineQuestionComponent,
    AddEditLoglineQuestionComponent,
    TraitComponent,
    AddEditTraitComponent,
    TonesQuestionsComponent,
    AddEditToneQuestionsComponent,
    WorldComponent,
    AddEditWorldComponent,
    CharacterCategoryComponent,
    AddEditCharacterCategoryComponent,
    ManageStoriesComponent,
    StoryDetailComponent,
    WorldsAspectsComponent,
    AddEditWorldAspectsComponent,
    WorldsSegmentsComponent,
    AddEditWorldSegmentsComponent,
    TutorialComponent,
    AddEditTutorialComponent,
    ManageMediaComponent,

    EditMediaComponent,
    CreateMediaComponent,
    ThemeOptionsComponent,
    EditThemeOptionComponent,
    AddThemeOptionComponent,
    


    CharacterPropertiesComponent,
    AddEditCharacterPropertiesComponent,
    ProjectPropertiesComponent,
    AddEditProjectPropertiesComponent,
    ToneOptionComponent,
    AddToneOptionComponent,
    EditToneOptionComponent,
    WorldSegmentOptionsComponent,
    AddEditWorldSegmentOptionsComponent,
    ProjectGeneralSettingsComponent,
    AddEditProjectGeneralSettingsComponent,
    ManageSidemenuComponent,
    EditSidemenuComponent,
    ManageArchTypesComponent,
    CreateArchetypeComponent,
    EditArchetypeComponent,
    LoglineDescriptionComponent,
    GlossaryComponent,
    AddEditGlossaryComponent,
    PaymentHistoryComponent,
    PaymentHistoryDetailComponent,
    SetupPayoffDescriptionComponent,
   
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    CKEditorModule
  ],

  providers: [UserService, ApiService, CookieService, RoleGuardService, AuthService, AuthGuard],

  bootstrap: [AppComponent]
})

export class AppModule { }
