import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  success_message: string;
  error_message: string;
  form: FormGroup;
  dataLoading: boolean;

  constructor(private titleService: Title,private router: Router, private http: HttpClient) { }

  
  ngOnInit() {
    this.titleService.setTitle('Forget Password - Story Blūprint');
    this.createForm();
  }

  createForm() {
    this.form = new FormGroup({
      "email": new FormControl("", Validators.compose([
        Validators.required,
        Validators.email,
        this.emailDomainValidator,
        Validators.maxLength(50)
      ])),
    });
  }

  emailDomainValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != null || control.value.length != 0) {
      let email = control.value;
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase()) ? null : { 'compare': true };
    }
    return null;
  } 

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  
  forgotPassword() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    if (this.form.valid) {
      this.http.post('/admin/forgotpasslink', this.form.value, {
        withCredentials: true,
        headers: new HttpHeaders().append('Content-Type', 'application/json')
      })
        .subscribe(res => {
          if (res) {
            this.success_message = res['message'];
            setTimeout(() => {
              this.router.navigate(['/login']);
            }, 3000);
          } else {
            this.error_message = 'Your account is inactive. Please contact admin.';
            this.dataLoading = false;
          }
        }, (err) => {
          if (err.status == 401) {
            this.router.navigate(['/user-login']);
          }
          this.error_message = 'Your account is inactive. Please contact admin.';
          this.dataLoading = false;
        });
    } else {
      this.validateAllFormFields(this.form);
      this.dataLoading = false;
    }
  }

}
