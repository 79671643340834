import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router, UrlTree } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';

declare var $: any;
@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.css']
})

export class EditEventComponent implements OnInit {


  templatelist: any;
  url: any;
  image_url_show = environment.image_url_show
  ckeConfig: any;
  isuserset: string;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  eventform: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  name = 'ng2-ckeditor';
  mycontent: string;
  log: string = '';
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  imageErr: String;
  imageFile: File;
  imageFileName: any;
  movements: any = [];
  imageArray: Array<String> = [];
  Filetype: Array<String> = [];
  filtyp: any;
  filesToUpload: Array<File> = [];
  event_id: any;
  event_image: string = "";
  imagestatus: boolean = false;
  ImagePreivewName: String = '';
  ImagePreivew: any = [];
  src: String = "";
  fileToUploadArr: any = {};
  imgUrlArr: any = {};
  public currentcolor = '#cf2121';
  constructor(private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private apiService: ApiService,
    private titleService: Title) {

    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
        var operationpermissions = this.navItems.find(elem => elem["id"] == 7);
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];

          if (this.url == 'edit_event' && !this.canedit) {
            this.router.navigate(['/manage_events']);
          }

        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })

    if (this.route.snapshot.params['id']) {
      this.event_id = this.route.snapshot.params['id'];
    } else {
      this.router.navigate(['/manage_events']);
    }
  }

  ngOnInit() {
    this.titleService.setTitle('Edit event- ' + environment.siteTitle);
    this.ckeConfig = {
      allowedContent: true,
      extraPlugins: 'divarea',
      height: "300px",
      width: "100%"
    };
    this.createForm();
    this.get_movements();
    this.getdetail();
  }
  public onChangeColor(color: string): void {
    this.eventform.get('event_color').setValue(color)
  }

  onChange1($event: any): void {
  }

  createForm() {
    this.eventform = new FormGroup({
      "event_name": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(500)
      ])),
      "placeholder": new FormControl(""),
      "long_desc_placeholder": new FormControl(""),
      // "short_description": new FormControl("", Validators.compose([
      //   Validators.required,
      //   Validators.maxLength(200)
      // ])),
      // "long_description": new FormControl("", Validators.compose([
      //   Validators.required,
      // ])),
      "movement": new FormControl("", Validators.compose([
        Validators.required,
      ])),
      "event_status": new FormControl("", Validators.compose([
        Validators.required,
      ])),
      "event_image": new FormControl("", Validators.compose([
        Validators.required,
      ])),
      "event_video_url": new FormControl(""),
      "event_color": new FormControl(""),
    })
  }
  get_movements() {

    this.apiService.postData('admin/get_movements', {})
      .subscribe(res => {

        if (res['status']) {
          this.movements = res['list']
        }
      }, (err) => {

      });
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  getdetail() {
    if (this.route.snapshot.params['id']) {

      this.event_id = this.route.snapshot.params['id'];
    }

    this.http.post('/admin/event_detail', { id: this.event_id }, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        this.dataLoading = false;
        if (res) {
          this.eventform.controls['event_name'].setValue(res['resp']['event_name']);
          this.eventform.controls['event_status'].setValue(res['resp']['event_status']);
          this.eventform.controls['placeholder'].setValue(res['resp']['placeholder']);
          this.eventform.controls['long_desc_placeholder'].setValue(res['resp']['long_desc_placeholder']);
          this.eventform.controls['event_color'].setValue(res['resp']['event_color']);
          // this.eventform.controls['short_description'].setValue(res['resp']['short_description']);
          // this.eventform.controls['long_description'].setValue(res['resp']['long_description']);
          this.eventform.controls['movement'].setValue(res['resp']['movement_id']);
          this.event_image = res['resp']['events_image_path'];
          if (this.event_image && this.event_image != null) {
            this.eventform.controls['event_video_url'].setValue(this.event_image);
          }
        }
        else {
          this.error_message = "Something went wrong !!";
          setTimeout(() => {
            this.router.navigate(['/manage_events'], { relativeTo: this.route });
          }, 3000);
        }
      }, (err) => {
        this.router.navigate(['/manage_events'], { relativeTo: this.route });
      });
  }

  imagesource(url) {
    var source = '';
    source = this.image_url_show + url;
    this.src = source;
  }


  onFileSeleted(event, type = 'update', formKey = '') {
    if (type == 'update') {
      if (formKey == '') {
        return false
      }
      const files = event.target.files;
      if (files.length > 0) {
        const reader = new FileReader();
        this.fileToUploadArr[formKey] = files[0];
        reader.readAsDataURL(files[0]);
        reader.onload = evt => {
          this.imgUrlArr[formKey] = reader.result;
        };
        if (this.fileToUploadArr[formKey]) {
          if (this.fileToUploadArr[formKey].type === 'image/jpeg' ||
            this.fileToUploadArr[formKey].type === 'image/png' ||
            this.fileToUploadArr[formKey].type === 'image/jpg') {
            if (this.fileToUploadArr[formKey].size < 2000000) {      // checking size here - 2MB           
            }
            else {
              this.imageErr = "File too large to upload(max 2MB)";
            }
          }
          else {
            this.imageErr = "File type should be jpg,jpeg,png";
          }
        }
      }
      else {
        delete this.fileToUploadArr[formKey]
        delete this.imgUrlArr[formKey]
      }

    }

  }

  fileChangeEvent(fileInput: any) {
    this.filesToUpload = <Array<File>>fileInput.target.files;
  }

  resetEventForm() {
    this.imgUrlArr['editForm'] = '';
    this.event_image = '';
    this.eventform.reset({
      event_name: '',
      placeholder: '',
      long_desc_placeholder: '',
      // short_description: '',
      // long_description: '',
      movement: '',
      event_status: '',
      event_image: '',
      event_video_url: '',
    });

  }

  saveData() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    this.eventform.removeControl('event_image');
    if (this.eventform.valid) {
      // let formData = new FormData();
      // const files: Array<File> = this.filesToUpload;

      // var html = this.eventform.value.long_description;
      // html = html.replace(/<[^>]*>/g, '');
      // html = html.replace(/&nbsp;/g, " ");
      // var text = html;
      // formData.append('updated_by', this.userID);
      // formData.append('event_id', this.event_id);
      // formData.append('event_name', this.eventform.value.event_name);
      // formData.append('placeholder', this.eventform.value.placeholder);
      // formData.append('event_color', this.eventform.value.event_color);
      // formData.append('long_desc_placeholder', this.eventform.value.long_desc_placeholder);
      // formData.append('short_description', this.eventform.value.short_description);
      // formData.append('long_description', text);
      // formData.append('event_status', this.eventform.value.event_status);
      // formData.append('movement_id', this.eventform.value.movement);

      // if (files.length > 0) {
      //   for (var i = 0; i < files.length; i++) {

      //     formData.append("events_image_path", files[i], files[i]['name']);
      //   }
      // }
      // else {
      //   formData.append('events_image_path', this.event_image);
      // }

      var formData={
        updated_by:this.userID,
        event_id:this.event_id,
        event_name:this.eventform.value.event_name,
        placeholder:this.eventform.value.placeholder,
        event_color:this.eventform.value.event_color,
        long_desc_placeholder:this.eventform.value.long_desc_placeholder,
        event_status:this.eventform.value.event_status,
        movement_id:this.eventform.value.movement,
      }

      this.http.post('/admin/edit_event', formData, {
      })
        .subscribe(res => {
          if (res['status']) {
            this.success_message = 'Event updated successfully';
            setTimeout(() => {
              this.dataLoading = false;

              this.router.navigate(['/manage_events']);
              this.success_message = '';
            }, 3000);
          }
          else {
            this.error_message = res['message'];
            this.dataLoading = false;
          }
        }, (err) => {
          this.error_message = "Event can't created";
          this.dataLoading = false;
        });
    } else {
      this.dataLoading = false;
      this.validateAllFormFields(this.eventform);
    }
  }

}





