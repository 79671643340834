import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  isuserset: string;
  userID: any;
  email1: string;
  role: string;
  success_message: String;
  error_message: String;
  dataLoading: boolean;
  second_dashboard: boolean = false;
  public navItems = [];
  menu_list: any = [];
  dashboard_data: any = [];
  totalusers:any;
  totalproject:any;
  homeHeaderData = { 'userName': null, 'menu_list': null };
  permission = { 'user_view': true, 'contact_view': true, 'support_view': true, };
  constructor(private userService: UserService,
    private apiService: ApiService,
    private router: Router,
    private titleService: Title) {
    this.userService.getuser().subscribe(userdata => {

      this.userID = userdata['id'];
      this.email1 = userdata['email1'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.menu_list = userdata['menu_list'];
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
        var operationpermissions = this.navItems.find(elem => elem["id"] == 1);
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.second_dashboard = true;
          }
        } else {
          this.second_dashboard = true;
        }
        var operationpermissions = this.navItems.find(elem => elem["id"] == 9);
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.permission.user_view = false;
          }
        }
        else {
          this.permission.user_view = false;
        }
      }
    },
      error => {
        this.router.navigate(['/login']);
      })
  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard- ' + environment.siteTitle);  
    this.dashboard();
  }


  dashboard() {
    this.apiService.postData('admin/dashboarddata')
      .subscribe(res => {   
        if (res['status']) { 
        this.totalusers=res['response']
        if(res['resp1']){
        this.totalproject=res['resp1'].length      
        }
      }
        else {         
        }
      })
  }

}
