import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-edit-project-general-settings',
  templateUrl: './add-edit-project-general-settings.component.html',
  styleUrls: ['./add-edit-project-general-settings.component.css']
})
export class AddEditProjectGeneralSettingsComponent implements OnInit {
  userID: any;
  isuserset: string;
  email: string;
  role: string;
  last_login: string;
  editSettingForm: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  name = 'ng2-ckeditor';
  mycontent: string;
  log: string = '';
  homeHeaderData = { 'userName': null, 'menu_list': null };
  url: string;
  gs_id: any = 0;
  ckeConfig: any;
  dataLoading: boolean;
  constructor(
                private userService: UserService,
                private router: Router,
                private route: ActivatedRoute,
                private http: HttpClient,
                private apiService: ApiService,
                private titleService: Title         
              ) {
                this.userService.getuser().subscribe(userdata => {
                  this.userID = userdata['id'];
                  this.email = userdata['email'];
                  this.isuserset = userdata['fullname'];
                  this.role = userdata['type'];
                  this.last_login = userdata['last_login'];
                  this.menu_list = userdata['menu_list'];
                  const tree: UrlTree = router.parseUrl(this.router.url);
                  this.url = this.router.url
                  this.url = this.url.substring(1);
                  this.url = this.url.split("/")[0];
                  if (userdata['type'] != 'superadmin') {
                    this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
                    var operationpermissions = this.navItems.find(elem => elem["id"] == 7);
                    if (typeof (operationpermissions) != 'undefined') {
                      if (!operationpermissions['view']) {
                        this.router.navigate(['/dashboard']);
                      }
                      this.canadd = operationpermissions['add_p'];
                      this.canedit = operationpermissions['edit_p'];
                      this.candelete = operationpermissions['delete_p'];
            
                      if (this.url == 'edit_event' && !this.canedit) {
                        this.router.navigate(['/project_general_settings']);
                      }
            
                    } else {
                      this.router.navigate(['/dashboard']);
                    }
                  }
                  else {
                    this.canadd = true;
                    this.canedit = true;
                    this.candelete = true;
                  }
                  this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
                },
                  error => {
                    this.router.navigate(['/login']);
                  })
            
                if (this.route.snapshot.params['id']) {
                  this.gs_id = this.route.snapshot.params['id'];
                } else {
                  this.router.navigate(['/project_general_settings']);
                }
               }

  ngOnInit() {
    this.titleService.setTitle('Edit Project General Seting- ' + environment.siteTitle);
    this.ckeConfig = {
      allowedContent: true,
      extraPlugins: 'divarea',
      height: "300px",
      width: "100%"
    };
    this.createForm();
    this.getdetail();
  }

  createForm() {
    this.editSettingForm = new FormGroup({
      "id": new FormControl(0, Validators.required),
      "data": new FormControl("", Validators.compose([
        Validators.required,
      ])),
      "status": new FormControl("", Validators.compose([
        Validators.required,
      ])),
      
    })
  }

  resetForm() {
    this.editSettingForm.reset({
      data: '',
      status: '',
    });

  }
  
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  getdetail() {
    if (this.route.snapshot.params['id']) {

      this.gs_id = this.route.snapshot.params['id'];
    }

    this.http.post('/admin/get-general-setting-detail', { id: this.gs_id }, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        this.dataLoading = false;
        if (res) {
          this.editSettingForm.controls['id'].setValue(res['resp']['id']);
          this.editSettingForm.controls['data'].setValue(res['resp']['data']);
          this.editSettingForm.controls['status'].setValue(res['resp']['status']);
        }
        else {
          this.error_message = "Something went wrong !!";
          setTimeout(() => {
            this.router.navigate(['/project_general_settings'], { relativeTo: this.route });
          }, 3000);
        }
      }, (err) => {
        this.router.navigate(['/project_general_settings'], { relativeTo: this.route });
      });
  }

  saveData() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    if (this.editSettingForm.valid) {
      

      this.http.post('/admin/edit_general_setting', this.editSettingForm.value, {
      })
        .subscribe(res => {
          if (res['status']) {
            this.success_message = 'General setting updated successfully';
            setTimeout(() => {
              this.dataLoading = false;

              this.router.navigate(['/project_general_settings']);
              this.success_message = '';
            }, 3000);
          }
          else {
            this.error_message = res['message'];
            this.dataLoading = false;
          }
        }, (err) => {
          this.error_message = "General setting can't be updated";
          this.dataLoading = false;
        });
    } else {
      this.dataLoading = false;
      this.validateAllFormFields(this.editSettingForm);
    }
  }

}
