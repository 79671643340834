import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Route, UrlTree } from '@angular/router';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
declare var $: any;
@Component({
  selector: 'app-edit-roles',
  templateUrl: './edit-roles.component.html',
  styleUrls: ['./edit-roles.component.css']
})
export class EditRolesComponent implements OnInit {
  public navItems = [];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  menu_list: any;
  url: any;
  sub_menu_list: any;
  isuserset: string;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  editform: FormGroup;
  success_message: String;
  error_message: String;
  dataLoading: boolean;
  roleid: Number;
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  homeHeaderData = { 'userName': null, 'menu_list': null };
  constructor(private userService: UserService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private titleService: Title) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);

      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];

      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
        var operationpermissions = this.navItems.find(elem => elem["id"] == '2');
        operationpermissions = operationpermissions['children'];
        operationpermissions = operationpermissions.find(element => element["id"] == '4');
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];

          if (this.url == 'edit_roles' && !this.canedit) {
            this.router.navigate(['/manage_roles']);
          }
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })
    if (this.route.snapshot.params['id']) {
      this.roleid = this.route.snapshot.params['id'];
    } else {
      this.router.navigate(['/manage_roles']);
    }
  }
  ngOnInit() {
    this.titleService.setTitle('Edit Role- ' + environment.siteTitle);

    this.createForm();
    this.getdetail();
  }

  createForm() {
    this.editform = new FormGroup({
      "title": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(50)
      ])),
      "status": new FormControl("", Validators.compose([
        Validators.required
      ])),
      "updated_by": new FormControl(""),
      "id": new FormControl(""),
      "type": new FormControl(""),
    })
  }
  viewchecked(id) {
    if ($("#" + id).is(':checked')) {
      $("." + id).removeAttr('disabled');
    } else {
      $("." + id).prop('checked', false);
      $("." + id).attr('disabled', true);
      if ($("." + id).hasClass("sub" + id)) {
        var subid = $(".sub" + id).attr('id');
        $(".sub" + id).prop('checked', false);
        $(".sub" + id).attr('disabled', true);
        $("." + subid).prop('checked', false);
        $("." + subid).attr('disabled', true);
      }
    }
  }
  getdetail() {
    this.http.post('/admin/edit_role', { id: this.roleid, type: 'get' }, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        this.dataLoading = false;
        if (res) {
          if (res['status']) {
            this.editform.controls['title'].setValue(res['detail']['title']);
            this.editform.controls['status'].setValue(res['detail']['status']);
            this.editform.controls['id'].setValue(res['detail']['id']);
            this.roleid = res['detail']['id'];
            this.sub_menu_list = res['permission'];
          }
        } else {
          this.error_message = res['message'];
          setTimeout(() => {
            this.router.navigate(['/manage_roles'], { relativeTo: this.route });
          }, 3000);
        }
      }, (err) => {

        this.router.navigate(['/manage_roles'], { relativeTo: this.route });
      });
  }
  submitRecord() {
    if (!this.route.snapshot.params['id']) {
      this.router.navigate(['/manage_roles']);
    } else {
      this.error_message = "";
      this.success_message = "";
      if (this.editform.valid) {
        this.dataLoading = true;
        var record_arr = [];
        $("input[name='menus[]'").map(function (i, el) {
          var menuid = $(this).val();
          var view = $("input[name='view[" + menuid + "]'").is(":checked");
          var add = $("input[name='add[" + menuid + "]'").is(":checked");
          var edit = $("input[name='edit[" + menuid + "]'").is(":checked");
          var remove = $("input[name='remove[" + menuid + "]'").is(":checked");
          record_arr.push({
            menu_id: menuid,
            view: view,
            add: add,
            edit: edit,
            remove: remove
          });
        });
        var formdata = {
          title: this.editform.controls['title'].value,
          status: this.editform.controls['status'].value,
          id: this.roleid,
          type: 'post',
          updated_by: this.userID,
          record_arr: record_arr
        };
        if ($(".viewrow:checked").length == 0) {
          this.error_message = "Please set role permissions.";
          this.dataLoading = false;
          setTimeout(() => {
            this.error_message = "";
          }, 2000);
        } else {
          this.http.post('/admin/edit_role', formdata, {
            withCredentials: true,
            headers: new HttpHeaders().append('Content-Type', 'application/json')
          })
            .subscribe(res => {
              if (res) {
                if (res['status']) {
                  this.success_message = res['message'];
                  setTimeout(() => {
                    this.router.navigate(['/manage_roles'], { relativeTo: this.route });
                  }, 1000);
                } else {
                  this.error_message = res['message'];
                  this.dataLoading = false;
                  setTimeout(() => {
                    this.error_message = "";
                  }, 3000);
                }
              }
            }, (err) => {
              this.dataLoading = false;
              this.error_message = "Error occured. please try again.";
            });
        }
      }
      else {
        this.validateAllFormFields(this.editform);
        this.dataLoading = false;
      }
    }
  }
  submitRecord_bkup() {
    if (!this.route.snapshot.params['id']) {
      this.router.navigate(['/manage_roles']);
    } else {
      this.error_message = "";
      this.success_message = "";
      if (this.editform.valid) {
        this.dataLoading = true;
        this.editform.controls['type'].setValue('post');
        this.editform.controls['updated_by'].setValue(this.userID);
        this.http.post('/admin/edit_role', this.editform.value, {
          withCredentials: true,
          headers: new HttpHeaders().append('Content-Type', 'application/json')
        })
          .subscribe(res => {
            if (res) {
              if (res['status']) {
                this.success_message = res['message'];
                setTimeout(() => {
                  this.router.navigate(['/manage_roles'], { relativeTo: this.route });
                }, 1000);
              } else {
                this.error_message = res['message'];
                this.dataLoading = false;
                setTimeout(() => {
                  this.error_message = "";
                }, 3000);
              }
            }
          }, (err) => {
            this.dataLoading = false;
            this.error_message = "Error occured. Please try again.";
          });
      }
      else {
        this.validateAllFormFields(this.editform);
        this.dataLoading = false;
      }
    }
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}

