import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import {Title} from '@angular/platform-browser'; 
@Component({
  selector: 'app-manage-mails',
  templateUrl: './manage-mails.component.html',
  styleUrls: ['./manage-mails.component.css']
})
export class ManageMailsComponent implements OnInit {
  templatelist: any;
  ckeConfig: any;
  isuserset: string;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  form: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  name = 'ng2-ckeditor';
  mycontent: string;
  log: string = '';
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  constructor(private userService: UserService,private router: Router, private http: HttpClient,private titleService: Title) { 
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list =userdata['menu_list'];
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
        var operationpermissions = this.navItems.find(elem=>elem["id"] == 6);
        if(typeof(operationpermissions)!='undefined'){
          if(!operationpermissions['view']){
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];         
        }else{
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list};
    },
      error => {
        this.router.navigate(['/login']);
      })
  }

  ngOnInit() {
    this.titleService.setTitle('Manage Mails- Story Bluprint');                          
    this.ckeConfig = {
      allowedContent: true,
      extraPlugins: 'divarea',
      height:"400px",
      width:"100%"
    };
    this.createForm()
    this.gettemplatedetail()
  }

  onChange1($event: any): void {
  }

  createForm() {
    this.form = new FormGroup({
      "type": new FormControl("", Validators.compose([
        Validators.required,                       
      ])),
      "subject": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(100)
       ])),
      "template": new FormControl("", Validators.compose([
        Validators.required
            ]))
    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  templatefunction() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    if (this.form.valid) {
      this.http.post('/admin/edit_templates', this.form.value,{
        withCredentials: true,
        headers: new HttpHeaders().append('Content-Type', 'application/json')
      })
        .subscribe(res => {
          if (res) {
           this.dataLoading = false;
            this.success_message = 'Mail template updated successfully';
            setTimeout(() => {
              this.success_message = '';
            }, 3000);
          }
        }, (err) => {
          this.error_message = "Mail template not updated";
          this.dataLoading = false;
        });
    } else {
      this.dataLoading = false;
      this.validateAllFormFields(this.form);
    }
  }

  public onChange(event): void {  // event will give you full breif of action
    const newVal = event.target.value;
    this.http.post('/admin/mail_template_value',{type:newVal}, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res) {
          this.form.controls['subject'].setValue(res['subject']);
          this.form.controls['template'].setValue(res['template']);
        }
      }, (err) => {
        console.log(err);
      });
  }

  gettemplatedetail() {
    this.http.post('/admin/mail_template', {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res) {
          this.templatelist=res;
        }
      }, (err) => {
        console.log(err);
      });
  }
  

}


