import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  constructor(private http: HttpClient, private cookieService: CookieService, private router: Router) {
  }

  getuser() {
    return this.http.get('/admin/' + this.cookieService.get('admintoken'), {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json').append('Cache-Control', 'no-cache')
    });
  }
}
