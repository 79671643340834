import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router, UrlTree } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-add-edit-world-segments',
  templateUrl: './add-edit-world-segments.component.html',
  styleUrls: ['./add-edit-world-segments.component.css']
})

export class AddEditWorldSegmentsComponent implements OnInit {

  isuserset: string;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  worldsegmentsform: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  buttontext: String = 'Add';
  pagetitle: String = 'Add'
  id: any;
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  worldlist: any;
  public pageSize: number = 10;
  public page_num: number = 1;
  public index: number = 0;
  cat_id: any = 0;
  sortcolumn: any = '';
  sort: any = 'DESC';
  url: any;
  constructor(private userService: UserService,
    private http: HttpClient,
    private router: Router,
    private apiService: ApiService,
    private titleService: Title,
    private route: ActivatedRoute, ) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];

      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));

        /* code added by dev@av start */
        var usrAccessMenus = JSON.parse(JSON.stringify(userdata['menu_arr']));
        var operationpermissions = usrAccessMenus.find(elem => elem["id"] == '26');
        /* code added by dev@av end */
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
          if (this.url == 'add_world_segments' && !this.canadd) {
            this.router.navigate(['/worlds_segments']);
          }
          if (this.url == 'edit_world_segments' && !this.canedit) {
            this.router.navigate(['/worlds_segments']);
          }

        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })
    this.id = this.route.snapshot.params['id'];
  }

  ngOnInit() {
    this.titleService.setTitle('Add Worlds Segments- ' + environment.siteTitle);
    this.createForm();
    this.getdetail();

    this.getworldlistdata();
  }

  sortby(column_name) {

    if (this.sortcolumn != column_name) {
      this.sort = 'ASC'
      this.sortcolumn = column_name
    }
    else {
      this.sort = this.sort == 'DESC' ? 'ASC' : 'DESC'
    }

  }

  createForm() {
    this.worldsegmentsform = new FormGroup({
      "title": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(100)
      ])),
      "category": new FormControl(""),
      "actiontype": new FormControl(""),
      "status": new FormControl(""),
      "pageSize": new FormControl(""),
      "page_num": new FormControl(""),
      "from_date": new FormControl(""),
      "to_date": new FormControl(""),
      "index": new FormControl(""),
      "cat_id": new FormControl(""),
      "world_id": new FormControl("", Validators.compose([
        Validators.required
      ])),
      "placeholder": new FormControl(""),

    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  formreset() {
    this.worldsegmentsform.reset();
    this.worldsegmentsform.controls['pageSize'].setValue(this.pageSize);
    this.worldsegmentsform.controls['page_num'].setValue(this.page_num);
    this.worldsegmentsform.controls['index'].setValue(this.index);
    this.getworldlistdata();

  }

  getdetail() {
    if (this.route.snapshot.params['id']) {
      this.buttontext = "Update"
      this.pagetitle = "Edit"
      this.apiService.postData('admin/world_segments_detail', { id: this.id })
        .subscribe(res => {
          this.dataLoading = false;
          if (res['status']) {
            this.worldsegmentsform.controls['title'].setValue(res['detail']['title']);
            this.worldsegmentsform.controls['world_id'].setValue(res['detail']['world_id']);
            this.worldsegmentsform.controls['placeholder'].setValue(res['detail']['placeholder']);
            this.titleService.setTitle('Edit Worlds Segments- ' + environment.siteTitle);
          } else {
            this.error_message = "Something went wrong !!";
            setTimeout(() => {
              this.router.navigate(['/worlds_segments']);
            }, 3000);
          }
        }, (err) => {

          this.router.navigate(['/worlds_segments']);
        });
    }
  }
  category_title(cat_id) {
    this.cat_id = cat_id;
    this.worldsegmentsform.controls['category'].setValue(cat_id);
  }


  getworldlistdata() {
    this.worldsegmentsform.controls['pageSize'].setValue(this.pageSize);
    this.worldsegmentsform.controls['page_num'].setValue(this.page_num);
    this.worldsegmentsform.controls['actiontype'].setValue('list');
    this.worldsegmentsform.value.sortcolumn = this.sortcolumn;
    this.worldsegmentsform.value.sort = this.sort;
    this.http.post('/admin/allworld', this.worldsegmentsform.value, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {

        if (res['status']) {
          this.worldlist = res['response'];
          this.index = res['index'];

        }
        else if (!res['status']) {
          this.router.navigate(['/dashboard']);
        }
      }, (err) => {

      });
  }
  saveData() {
    if (this.buttontext == 'Add') {
      this.error_message = "";
      this.success_message = "";
      this.dataLoading = true;
      this.worldsegmentsform.controls['cat_id'].setValue(this.cat_id);
      this.worldsegmentsform.addControl('id', new FormControl(this.userID, Validators.required));

      if (this.worldsegmentsform.valid) {

        this.apiService.postData('admin/add_world_segments', this.worldsegmentsform.value)
          .subscribe(res => {

            if (res['status']) {
              this.success_message = 'World segment added successfully';
              setTimeout(() => {
                this.dataLoading = false;

                this.router.navigate(['/worlds_segments']);
                this.success_message = '';
              }, 3000);
            }
            else {
              this.error_message = "World segment can't added";
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "World segment can't added";
            this.dataLoading = false;
          });

      } else {
        this.dataLoading = false;
        this.validateAllFormFields(this.worldsegmentsform);
      }
    }

    if (this.buttontext == 'Update') {
      this.error_message = "";
      this.success_message = "";
      this.dataLoading = true;
      this.worldsegmentsform.addControl('id', new FormControl(this.id, Validators.required));
      if (this.worldsegmentsform.valid) {
        let worldAspectForm = this.worldsegmentsform.value;
        let formdata = { title: worldAspectForm.title, placeholder: worldAspectForm.placeholder, world_id: worldAspectForm.world_id, id: worldAspectForm.id }

        this.apiService.postData('admin/update_world_segments', formdata)
          .subscribe(res => {
            if (res['status']) {
              this.success_message = 'World segment updated successfully';
              setTimeout(() => {
                this.dataLoading = false;

                this.router.navigate(['/worlds_segments']);
                this.success_message = '';
              }, 3000);
            }
            else {
              this.error_message = "World segment can't updated";
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "World segment can't updated";
            this.dataLoading = false;
          });
      } else {
        this.dataLoading = false;
        this.validateAllFormFields(this.worldsegmentsform);
      }

    }

  }
}

