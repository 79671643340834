import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router, UrlTree } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-add-edit-tone-questions',
  templateUrl: './add-edit-tone-questions.component.html',
  styleUrls: ['./add-edit-tone-questions.component.css']
})
export class AddEditToneQuestionsComponent implements OnInit {

  templatelist: any;
  url: any;
  title: any;
  title1: any;
  ckeConfig: any;
  isuserset: string;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  toneform: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  name = 'ng2-ckeditor';
  movements: any = [];
  titlelist: any = [];
  log: string = '';
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  imageErr: String;
  imageFile: File;
  imageFileName: any;
  imageArray: Array<String> = [];
  Filetype: Array<String> = [];
  filtyp: any;
  filesToUpload: Array<File> = [];
  event_id: any;
  id1: any;
  id: any
  buttontext: String = 'Add';
  pagetitle: String = 'Add'
  ImagePreivew: any = [];
  checkboxval: any;
  constructor(private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private apiService: ApiService,
    private titleService: Title,
    private route: ActivatedRoute, ) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];

      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));

        /* code added by dev@av start */
        var usrAccessMenus = JSON.parse(JSON.stringify(userdata['menu_arr']));
        var operationpermissions = usrAccessMenus.find(elem => elem["id"] == '22');
        /* code added by dev@av end */
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
          if (this.url == 'add_tone_questions' && !this.canadd) {
            this.router.navigate(['/tone_questions']);
          }
          if (this.url == 'edit_tone_questions' && !this.canedit) {
            this.router.navigate(['/tone_questions']);
          }

        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })
    this.id = this.route.snapshot.params['id'];


  }

  ngOnInit() {
    this.titleService.setTitle('Add Tone Questions- ' + environment.siteTitle);
    this.createForm()
    this.get_title()
    this.getdetail();

  }

  onChange1($event: any): void {
  }

  createForm() {
    this.toneform = new FormGroup({

      "question": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(500)
      ])),


      "type": new FormControl("", Validators.compose([
        Validators.required
      ])),
      "placeholder": new FormControl(""),
      "sort_description": new FormControl(""),
      "tones_id": new FormControl("", Validators.compose([Validators.required])),
      "is_active": new FormControl("", Validators.compose([Validators.required])),
    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  placeholder(event: any) {
    this.checkboxval = event.target.value

  }


  get_title() {

    this.apiService.postData('admin/tone_question_title', {})
      .subscribe(res => {

        if (res['status']) {
          this.titlelist = res['list']

        }
      }, (err) => {

        
      });
  }
  getdetail() {
    if (this.route.snapshot.params['id']) {
      this.buttontext = "Update"
      this.pagetitle = "Edit"
      this.apiService.postData('admin/tone_questions_detail', { id: this.id })
        .subscribe(res => {

          this.dataLoading = false;
          if (res['status']) {
            this.toneform.controls['tones_id'].setValue(res['detail']['tones_id']);
            this.toneform.controls['question'].setValue(res['detail']['question']);

            this.toneform.controls['type'].setValue(res['detail']['type']);
            this.toneform.controls['placeholder'].setValue(res['detail']['placeholder']);
            this.toneform.controls['is_active'].setValue(res['detail']['is_active']);
            this.checkboxval = res['detail']['type']


            this.titleService.setTitle('Edit Tone Questions- ' + environment.siteTitle);
          } else {
            this.error_message = "Something went wrong !!";
            setTimeout(() => {
              this.router.navigate(['/tone_questions']);
            }, 3000);
          }
        }, (err) => {
          
          this.router.navigate(['/tone_questions']);
        });
    }
  }



  saveData() {
    if (this.buttontext == 'Add') {
      this.error_message = "";
      this.success_message = "";
      this.dataLoading = true;
      let formData = new FormData();
      var deafult = this.movements.find(element => element.movements_id == this.toneform.value.movements_id)

      formData.append('created_by', this.userID);
      formData.append('tones_id', this.toneform.value.tones_id);
      formData.append('question', this.toneform.value.question);
      formData.append('type', this.toneform.value.type);
      formData.append('placeholder', this.toneform.value.placeholder);
      formData.append('sort_description', this.toneform.value.sort_description);
      formData.append('is_active', this.toneform.value.is_active);
      if (this.toneform.valid) {
        console.log(this.toneform.value,"this.toneform.value")
        this.http.post('/admin/add_tone_questions', formData, {
        })
          .subscribe(res => {

            if (res['status']) {
              this.success_message = 'Question added successfully';
              setTimeout(() => {
                this.dataLoading = false;

                this.router.navigate(['/tone_questions']);
                this.success_message = '';
              }, 3000);
            }
            else {
              this.error_message = "Question can't added";
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "Question  can't added";
            this.dataLoading = false;
          });
      }
      else {
        this.dataLoading = false;
        this.validateAllFormFields(this.toneform);
      }
    }
    else {

      this.buttontext == 'Update'
      this.pagetitle == 'Update'
      this.error_message = "";
      this.success_message = "";
      this.dataLoading = true;

      this.toneform.addControl('tones_id', new FormControl(this.toneform.value.tones_id, Validators.required));
      this.toneform.addControl('id', new FormControl(this.id, Validators.required));
      if (this.toneform.valid) {
        this.apiService.postData('admin/update_tone_question', this.toneform.value)
          .subscribe(res => {


            if (res['status']) {
              this.success_message = 'Question updated successfully';
              setTimeout(() => {
                this.dataLoading = false;

                this.router.navigate(['/tone_questions']);
                this.success_message = '';
              }, 3000);
            }
            else {
              this.error_message = " Question can't updated";
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "Question can't updated";
            this.dataLoading = false;
          });

      }
      else {
        this.dataLoading = false;
        this.validateAllFormFields(this.toneform);
      }



    }
  }
}




