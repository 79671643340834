import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router, UrlTree } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-edit-archetype',
  templateUrl: './edit-archetype.component.html',
  styleUrls: ['./edit-archetype.component.css']
})
export class EditArchetypeComponent implements OnInit {
  isuserset: string;
  userID: any;
  url: any;
  email: string;
  role: string;
  last_login: string;
  form: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  archetype_id: any;
  image_url_show = environment.image_url_show;
  src: String = "";
  imagestatus: boolean = false;
  ImagePreivew: any = [];
  ImagePreivewName: String = '';
  imageErr: String;
  imageArray: Array<String> = [];
  Filetype: Array<String> = [];
  image: string = '';
  imageFileName: any;
  filtyp: any;
  filesToUpload: Array<File> = [];
  resetimg: any;

  fileToUploadArr: any = {};
  imgUrlArr: any = {};
  constructor(private userService: UserService, private router: Router, private http: HttpClient, private titleService: Title, private route: ActivatedRoute, private apiService: ApiService) { 
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];

      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));

        /* code added by dev@av start */
        var usrAccessMenus = JSON.parse(JSON.stringify(userdata['menu_arr']));
        var operationpermissions = usrAccessMenus.find(elem => elem["id"] == '40');
        /* code added by dev@av end */
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];

          if (this.url == 'edit_archetype' && !this.canedit) {
            this.router.navigate(['/manage_archetype']);
          }


        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })
    if (this.route.snapshot.params['id']) {
      this.archetype_id = this.route.snapshot.params['id'];
    } else {
      this.router.navigate(['/manage_archetype']);
    }
  }

  ngOnInit() {
    this.titleService.setTitle('Manage Archetype- Story Bluprint');
    this.titleService.setTitle('Edit Archetype- ' + environment.siteTitle);
    this.createForm();
    this.getdetail();
  }

  createForm() {
    this.form = new FormGroup({
      "name": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(100)
      ])),
      "status": new FormControl("", Validators.compose([
        Validators.required
      ])),
      "image": new FormControl("", Validators.compose([
        Validators.required
      ])),
    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  getdetail() {
    this.http.post('/admin/archetype_detail', { id: this.archetype_id }, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })

      .subscribe(res => {
        this.dataLoading = false;
        if (res) {

          this.form.controls['name'].setValue(res['resp']['name']);
          this.form.controls['status'].setValue(res['resp']['status']);
          this.image = res['resp']['image_path']
          // if (this.image && this.image != null) {
          //   this.form.controls['image'].setValue(this.image);
          // }

        } else {
          this.error_message = "Something went wrong !!";
          setTimeout(() => {
            this.router.navigate(['/manage_archetype'], { relativeTo: this.route });
          }, 3000);
        }
      }, (err) => {

        this.router.navigate(['/manage_archetype'], { relativeTo: this.route });
      });
  }



  imagesource(url) {

    var source = '';
    source = this.image_url_show + url;
    this.src = source;
  }

  onFileSeleted(event, type = 'update', formKey = '') {
    if (type == 'update') {
      if (formKey == '') {
        return false
      }
      const files = event.target.files;
      if (files.length > 0) {
        const reader = new FileReader();
        this.fileToUploadArr[formKey] = files[0];
        reader.readAsDataURL(files[0]);
        reader.onload = evt => {
          this.imgUrlArr[formKey] = reader.result;
        };
        if (this.fileToUploadArr[formKey]) {
          if (this.fileToUploadArr[formKey].type === 'image/jpeg' ||
            this.fileToUploadArr[formKey].type === 'image/png' ||
            this.fileToUploadArr[formKey].type === 'image/jpg') {
            if (this.fileToUploadArr[formKey].size < 2000000) {      // checking size here - 2MB           
            }
            else {
              this.imageErr = "File too large to upload(max 2MB)";
            }
          }
          else {
            this.imageErr = "File type should be jpg,jpeg,png";
          }
        }
      }
      else {
        delete this.fileToUploadArr[formKey]
        delete this.imgUrlArr[formKey]
      }

    }

  }

  fileChangeEvent(fileInput: any) {
    this.filesToUpload = <Array<File>>fileInput.target.files;
  }

  resetArchetypeImg() {
    this.imgUrlArr['editForm'] = '';
    this.image = '';
  }


  saveData() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;

    this.form.addControl('updated_by', new FormControl(this.userID, Validators.required));
    this.form.addControl('id', new FormControl(this.archetype_id, Validators.required));
    let formData = new FormData();

    if (this.form.valid) {
      const files: Array<File> = this.filesToUpload;

      delete this.form.value.image;
      for (let key in this.form.value) {
        formData.append(key, this.form.value[key]);

      }


      if (files.length > 0) {
        for (var i = 0; i < files.length; i++) {

          formData.append("image_path", files[i], files[i]['name']);
        }
      }
      else {

        formData.append('image_path', this.image);
      }
      this.http.post('/admin/edit_archetype', formData)
        .subscribe(res => {
          if (res['status']) {
            this.success_message = 'Archetype updated successfully';
            setTimeout(() => {
              this.dataLoading = false;

              this.router.navigate(['/manage_archetype']);
              this.success_message = '';
            }, 3000);
          }
          else {

            this.error_message = res['message'];

            this.dataLoading = false;
          }
        }, (err) => {
          this.error_message = "Archetype can't updated";
          this.dataLoading = false;
        });
    } else {
      this.dataLoading = false;
      this.validateAllFormFields(this.form);
    }
  }

}
