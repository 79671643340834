import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { UserService } from '../user.service';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';
@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})

export class AdminProfileComponent implements OnInit {
  isuserset: string;
  userID: any;
  email1: string;
  menu_list: any = [];
  role: String;
  profileform: FormGroup;
  success_message: String;
  error_message: String;
  dataLoading: boolean;
  homeHeaderData = { 'userName': null, 'menu_list': null };

  constructor(private userService: UserService,
    private router: Router, private apiService: ApiService,
    private route: ActivatedRoute, private titleService: Title) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email1 = userdata['email1'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.menu_list = userdata['menu_list'];
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list }
      this.getprofile()
    },
      error => {
        this.router.navigate(['/login']);
      })
  }

  ngOnInit() {
    this.titleService.setTitle('Profile - ' + environment.siteTitle);
    this.createForm()
  }

  createForm() {
    this.profileform = new FormGroup({
      "fullname": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(50)
      ])),
      "email": new FormControl("", Validators.compose([
        Validators.required,
        this.emailDomainValidator,
        Validators.maxLength(50)
      ])),
    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  emailDomainValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != null || control.value.length != 0) {
      let email = control.value;
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase()) ? null : { 'compare': true };
    }
    return null;
  }

  keyPress(event: any) {
    const pattern = /[0-9\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  profilefunction() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    this.profileform.addControl('id', new FormControl(this.userID));
    if (this.profileform.valid) {
      this.apiService.postData('admin/profiles', this.profileform.value)
        .subscribe(res => {
          if (res) {
            if (res['status']) {
              let id = res['response']['_id'];
              this.success_message = 'Profile updated successfully';
              setTimeout(() => {
                this.dataLoading = false;
                this.success_message = '';

              }, 1500);
            }
            else {
              this.error_message = res['message'];
              this.dataLoading = false;
            }
          }
          else {
            this.error_message = "Profile not updated";
            this.dataLoading = false;
          }
        }, (err) => {
          this.dataLoading = false;
          this.error_message = "Profile not updated";
        });
    } else {
      this.validateAllFormFields(this.profileform);
      this.dataLoading = false;
    }
  }

  getprofile() {
    
    this.apiService.postData('admin/profile', { id: this.userID })
      .subscribe(res => {
        if (res) {
          this.profileform.controls['fullname'].setValue(res['fullname']);
          this.profileform.controls['email'].setValue(res['email']);
        }
      }, (err) => {
        
      });
  }
}




