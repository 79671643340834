import { Injectable }     from '@angular/core';
import { CanActivate }    from '@angular/router';
import { AuthService } from './auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(public auth: AuthService, public router: Router) {}

  // canActivate() {
  //   // this.auth.isAuthenticated(function(res){
  //   //   this.router.navigate(['user-login']);
  //   //   return false;
  //   // })
  //   return true;
    
  // }

  canActivate(): Promise<boolean> {
    // this will be passed from the route config
    // on the data property

    return new Promise<boolean>((resolve, reject) => {
      var page = this;
      page.auth.isAuthenticated(function (res) {
  
            if (res['status']) {
              resolve(true);                
            }
            else {
                resolve(false);
                page.router.navigate(['/user-login']);
            }
        })

    });

}

}