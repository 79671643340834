import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Route, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { ValidationErrors, FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserService } from '../user.service';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';
@Component({
  selector: 'app-create-admin',
  templateUrl: './create-admin.component.html',
  styleUrls: ['./create-admin.component.css']
})
export class CreateAdminComponent implements OnInit {
  isuserset: string;
  userID: any;
  email: string;
  role: string;
  url: any;
  menu_list: any = [];
  last_login: string;
  homeHeaderData = { 'userName': null, 'menu_list': null };
  adminform: FormGroup;
  success_message: String;
  error_message: String;
  dataLoading: boolean;
  rolelist: any;
  edituser_id: String = '';
  pageMode: String = 'create';
  ispasswordchanged: Number = 0;
  oldpasswordvalue: String = '';
  buttontext: String = 'Create';
  showLoginHistory: boolean = false;
  allLoginDetails: any;
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  pageName: String = 'Create Admin';
  public navItems = [];
  public href: string = ""
  urlStr: any;
  constructor(private userService: UserService, private titleService: Title, private apiService: ApiService, private route: ActivatedRoute, private router: Router, ) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];

      if (userdata['type'] != 'superadmin') {
        this.navItems = userdata['menu_list'];
        var operationpermissions = this.navItems.find(elem => elem["id"] == '2');
        if (typeof (operationpermissions) != 'undefined') {
          operationpermissions = operationpermissions['children'];
          operationpermissions = operationpermissions.find(element => element["id"] == '3');
          if (typeof (operationpermissions) != 'undefined') {
            if (!operationpermissions['view']) {
              this.router.navigate(['/dashboard']);
            }
            this.canadd = operationpermissions['add_p'];
            this.canedit = operationpermissions['edit_p'];
            this.candelete = operationpermissions['delete_p'];
            if (this.url == 'create_admin' && !this.canadd) {
              this.router.navigate(['/manage_admin']);
            }
            if (this.url == 'edit_admin' && !this.canedit) {
              this.router.navigate(['/manage_admin']);
            }

          } else {
            this.router.navigate(['/dashboard']);
          }
        }
        else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })

    this.apiService.postData('admin/get_roles', {})
      .subscribe(res => {
        if (res) {
          var newrolelist = [];
          if (this.route.snapshot.params['id']) {
            this.rolelist = res['list'];
          }
          else {
            for (let index1 = 0; index1 < res['list'].length; index1++) {
              const element = res['list'][index1];
              if (element.status == 'Active') {
                newrolelist.push(element);
              }
            }
            this.rolelist = newrolelist;
          }
        } else {
        }
      }, (err) => {

      });

    this.edituser_id = this.route.snapshot.params['id'];
    if (this.edituser_id) {
      this.pageName = 'Edit Admin';
      this.buttontext = 'Update';
      this.pageMode = 'edit';
      this.showLoginHistory = true;

      this.apiService.postData('admin/adminlist', { type: 'editadmin', id: this.edituser_id })
        .subscribe(res => {
          var userdetails = res['response'][0];
          this.adminform.patchValue({
            "fullname": userdetails['fullname'],
            "email": userdetails['email'],
            "password": '',
            "confirmPassword": '',
            "role_id": userdetails['role_id'],
            "created_by": userdetails['created_by']
          });
          this.adminform.controls['password'].setValidators([]);
          this.adminform.controls['confirmPassword'].setValidators([]);
          this.adminform.controls['password'].updateValueAndValidity();
          this.adminform.controls['confirmPassword'].updateValueAndValidity();
          this.adminform.get('password').valueChanges.subscribe(
            (mode: string) => {
              if (mode == '' && mode != this.oldpasswordvalue) {
                const passwordControl = this.adminform.get('password');
                const confirmPasswordControl = this.adminform.get('confirmPassword');
                passwordControl.setValidators([]);
                confirmPasswordControl.setValidators([]);
                this.oldpasswordvalue = mode;
                this.ispasswordchanged = 0;
                passwordControl.updateValueAndValidity();
                confirmPasswordControl.updateValueAndValidity();

              }
              else if (mode != '' && mode != this.oldpasswordvalue) {
                const passwordControl = this.adminform.get('password');
                const confirmPasswordControl = this.adminform.get('confirmPassword');
                passwordControl.setValidators([Validators.minLength(6),
                Validators.maxLength(20),
                Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&,.*-]).{6,20}$')]);
                confirmPasswordControl.setValidators([
                  this.compareValidator('password')]);
                this.oldpasswordvalue = mode;
                this.ispasswordchanged = 1;
                passwordControl.updateValueAndValidity();
                confirmPasswordControl.updateValueAndValidity();

              }
            });
          this.getalllogins();
        }, (err) => {

          setTimeout(() => {
            this.router.navigate(['/manage_admin']);
          }, 0);
        });
    }
    this.href = this.router.url;

    const tree: UrlTree = router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const urlArr: UrlSegment[] = g.segments;
    this.urlStr = urlArr[0].path;

  }

  ngOnInit() {
    var title = this.pageName + ' - ' + environment.siteTitle;
    this.titleService.setTitle(title);
    this.createForm();
  }

  getalllogins() {
    this.apiService.postData('admin/Alllogin', { userid: this.edituser_id })
      .subscribe(res => {
        if (res) {
          this.allLoginDetails = res;
        }
      }, (err) => {
        if (err.status == 401) {
          this.router.navigate(['/user-login']);
        }
      });
  }
  createForm() {
    this.adminform = new FormGroup({
      "fullname": new FormControl("", Validators.compose([
        Validators.required,
        this.noWhitespaceValidator,
        Validators.maxLength(50)
      ])),
      "email": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(50),
        this.emailDomainValidator
      ])),
      "password": new FormControl("", Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20),
        Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&,.*-]).{6,20}$')
      ])),
      "confirmPassword": new FormControl("", Validators.compose([
        Validators.required,
        this.compareValidator('password')
      ])),
      "role_id": new FormControl("", Validators.compose([
        Validators.required
      ])),
      "created_by": new FormControl("")
    })
  }
  resetadmin() {
    this.adminform.reset({
      fullname: '',
      email: '',
      password: '',
      confirmPassword: '',
      role_id: '',
    });

  }

  saveData() {
    if (this.pageMode == 'create') {
      this.error_message = "";
      this.success_message = "";
      this.dataLoading = true;
      if (this.adminform.valid) {
        this.adminform.patchValue({ created_by: this.userID });

        this.apiService.postData('admin/createadmin', this.adminform.value)
          .subscribe(res => {
            if (res) {
              if (res['status']) {
                this.adminform.reset();
                this.success_message = 'Admin created successfully';
                setTimeout(() => {
                  this.error_message = '';
                  this.router.navigate(['/manage_admin']);
                }, 2000);
              }
              else {
                this.error_message = res['message'];
                this.dataLoading = false;
              }
            } else {
              this.error_message = "Something Went Wrong!";
              this.dataLoading = false;
            }
          }, (err) => {
            if (err.status == 500) {
              this.router.navigate(['/dashboard']);
            }
            this.error_message = "Something Went Wrong!";
            this.dataLoading = false;
          });
      } else {
        this.validateAllFormFields(this.adminform);
        this.dataLoading = false;
      }
    }
    if (this.pageMode == 'edit') {
      if (this.adminform.valid) {

        this.apiService.postData('admin/updateadmin', {
          id: this.edituser_id,
          ispasswordchanged: this.ispasswordchanged,
          formdata: this.adminform.value,
          updated_by: this.userID
        })
          .subscribe(res => {
            if (res) {
              if (res['status']) {
                this.success_message = 'Profile updated successfully';
                setTimeout(() => {
                  this.dataLoading = false;
                  this.success_message = '';
                  this.error_message = '';
                  this.router.navigate(['/manage_admin']);
                }, 2000);
              }
              else {
                this.error_message = res['message'];
                this.dataLoading = false;
              }
            }
            else {
              this.error_message = "Something went wrong!";
              this.dataLoading = false;
            }
          }, (err) => {
            this.dataLoading = false;
            this.error_message = "Something went wrong";
          });
      }
      else {
        this.validateAllFormFields(this.adminform);
        this.dataLoading = false;
      }
    }
  }

  public noWhitespaceValidator(control: AbstractControl): { [key: string]: boolean } | null {

    if (control.value === null || control.value.length === 0) {
      return null;
    }
    const isWhitespace = (control.value || '').trim().length === 0;
    return isWhitespace ? {
      'whitespace': true
    } : null;
  }

  emailDomainValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != null) {
      if (control.value.length != 0) {
        let email = control.value;
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase()) ? null : { 'compare': true };
      }
      return null;
    }
    return null;
  }

  compareValidator(controlNameToCompare: string):
    ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (c.value === null || c.value.length === 0) {
        return null;
      }
      const controlToCompare = c.root.get(controlNameToCompare);
      if (controlToCompare) {
        const subscription: Subscription = controlToCompare.valueChanges.subscribe(() => {
          c.updateValueAndValidity(); subscription.unsubscribe();
        });
      }
      return controlToCompare && controlToCompare.value !== c.value ? {
        'compare': true
      } : null;
    };
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
