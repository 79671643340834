import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router, UrlTree } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-add-edit-world',
  templateUrl: './add-edit-world.component.html',
  styleUrls: ['./add-edit-world.component.css']
})
export class AddEditWorldComponent implements OnInit {

  isuserset: string;
  url: any;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  worldform: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  buttontext: String = 'Add';
  pagetitle: String = 'Add'
  id: any;
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  constructor(private userService: UserService,
    private router: Router,
    private apiService: ApiService,
    private titleService: Title,
    private route: ActivatedRoute, ) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];

      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));

        /* code added by dev@av start */
        var usrAccessMenus = JSON.parse(JSON.stringify(userdata['menu_arr']));
        var operationpermissions = usrAccessMenus.find(elem => elem["id"] == '24');
        /* code added by dev@av end */
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
          if (this.url == 'add_world' && !this.canadd) {
            this.router.navigate(['/world']);
          }
          if (this.url == 'edit_world' && !this.canedit) {
            this.router.navigate(['/world']);
          }

        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })
    this.id = this.route.snapshot.params['id'];
  }

  ngOnInit() {
    this.titleService.setTitle(this.pagetitle + ' ' + 'Worlds - ' + environment.siteTitle);

    this.createForm();
    this.getdetail();
  }
  createForm() {
    this.worldform = new FormGroup({
      "title": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(100)
      ])),
      "description": new FormControl(""),
    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  getdetail() {
    if (this.route.snapshot.params['id']) {
      this.buttontext = "Update"
      this.pagetitle = "Edit"
      this.apiService.postData('admin/world_detail', { id: this.id })
        .subscribe(res => {
          this.dataLoading = false;
          if (res['status']) {
            this.worldform.controls['title'].setValue(res['detail']['title']);
            this.worldform.controls['description'].setValue(res['detail']['description']);

            this.titleService.setTitle(this.pagetitle + ' ' + 'Worlds - ' + environment.siteTitle);
          } else {
            this.error_message = "Something went wrong !!";
            setTimeout(() => {
              this.router.navigate(['/world']);
            }, 3000);
          }
        }, (err) => {

          this.router.navigate(['/world']);
        });
    }
  }
  saveData() {
    if (this.buttontext == 'Add') {
      this.error_message = "";
      this.success_message = "";
      this.dataLoading = true;
      this.worldform.addControl('id', new FormControl(this.userID, Validators.required));

      if (this.worldform.valid) {

        this.apiService.postData('admin/add_world', this.worldform.value)
          .subscribe(res => {

            if (res['status']) {
              this.success_message = 'World added successfully';
              setTimeout(() => {
                this.dataLoading = false;
                this.router.navigate(['/world']);
                this.success_message = '';
              }, 3000);
            }
            else {
              this.error_message = "World can't added";
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "World can't added";
            this.dataLoading = false;
          });
      } else {
        this.dataLoading = false;
        this.validateAllFormFields(this.worldform);
      }
    }

    if (this.buttontext == 'Update') {
      this.error_message = "";
      this.success_message = "";
      this.dataLoading = true;

      this.worldform.addControl('id', new FormControl(this.id, Validators.required));
      if (this.worldform.valid) {
        this.apiService.postData('admin/update_world', this.worldform.value)
          .subscribe(res => {

            if (res['status']) {
              this.success_message = 'World updated successfully';
              setTimeout(() => {
                this.dataLoading = false;

                this.router.navigate(['/world']);
                this.success_message = '';
              }, 3000);
            }
            else {
              this.error_message = "World can't updated";
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "World can't updated";
            this.dataLoading = false;
          });
      } else {
        this.dataLoading = false;
        this.validateAllFormFields(this.worldform);
      }

    }

  }
}

