// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



// for local sys
// export const environment = {
//   production: false,
//   apiUrl: 'http://localhost:3600/',
//   image_url_show: 'http://localhost/storyblueprint/public/images/',
//  // image_url:'/var/www/html/storyblueprint/public/images/',
//   siteTitle:'Story Bluprint',
// };


// for Server
export const environment = {
  production: false,
  apiUrl: 'https://admin.storybluprint.io/',	
  image_url_show: 'https://storybluprint.io/public/images/',	
  image_url:'/home/Projects/storybluprint/app/front/public/images/',
  siteTitle:'Story Bluprint',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
