import { Injectable } from '@angular/core';
import { environment } from "../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private cookieService: CookieService, private router: Router) {
  }

  getData(url: string) {
    return this.http.get(environment.apiUrl + url, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json').append('Cache-Control', 'no-cache')
    });
  }

  postData(url: string, data?: any) {
    return this.http.post(environment.apiUrl + url, data, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

}
