import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';
declare var $: any;
@Component({
  selector: 'app-tones',
  templateUrl: './tones.component.html',
  styleUrls: ['./tones.component.css']
})
export class TonesComponent implements OnInit {
  public navItems = [];
  isuserset: string;
  userID: any;
  role: string;
  public pageSize: number = 10;
  public page_num: number = 1;
  public index: number = 0;
  findform: FormGroup;
  id1: String;
  deleteid: String;
  count: String;
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  recordlist: any;
  error_message: String;
  success_message: String;
  sortcolumn: any = '';
  sort: any = 'DESC';
  homeHeaderData = { 'userName': null, 'menu_list': null };
  constructor(private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private titleService: Title) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.menu_list = userdata['menu_list'];
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
        /* code added by dev@av start */
        var usrAccessMenus = JSON.parse(JSON.stringify(userdata['menu_arr']));
        var operationpermissions = usrAccessMenus.find(elem => elem["id"] == '20' || elem['id'] == '21');
        /* code added by dev@av end */
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
    },
      error => {
        this.router.navigate(['/login']);
      })
  }

  ngOnInit() {
    this.titleService.setTitle('Manage Tone- ' + environment.siteTitle);
    this.createForm();
    this.getList(this.pageSize, this.page_num, this.index);
    this.getcount();
    var script = document.createElement('script');
    script.src = '../../assets/js/bootstrap-datepicker.min.js';
    var head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
  }

  sortby(column_name) {
    if (this.sortcolumn != column_name) {
      this.sort = 'ASC'
      this.sortcolumn = column_name
    }
    else {
      this.sort = this.sort == 'DESC' ? 'ASC' : 'DESC'
    }
    this.getList(this.pageSize, this.page_num, this.index);
    this.getcount();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      var formvl = this.findform;
      $('#datetimepicker1').datepicker({
        format: "dd/mm/yyyy",
        autoclose: true,
      }).on('changeDate', function (ev) {
        var dateObject = $(this).datepicker("getDate");
        var d = dateObject.getDate();
        if (dateObject.getDate() < 10) {
          d = '0' + dateObject.getDate();
        }
        var dateString = dateObject.getFullYear() + '-' + (dateObject.getMonth() + 1) + '-' + d;
        formvl.controls['from_date'].setValue(dateString);
        $(this).datepicker('hide');
      });
      $('#datetimepicker2').datepicker({
        format: "dd/mm/yyyy",
        autoclose: true,
        gotoCurrent: true,
        maxDate: "+1d",
      }).on('changeDate', function (ev) {
        var dateObject = $(this).datepicker("getDate");
        var d = dateObject.getDate();
        if (dateObject.getDate() < 10) {
          d = '0' + dateObject.getDate();
        }
        var dateString = dateObject.getFullYear() + '-' + (dateObject.getMonth() + 1) + '-' + d;
        formvl.controls['to_date'].setValue(dateString);
        $(this).datepicker('hide');
      });

    }, 2000);
  }

  createForm() {
    this.findform = new FormGroup({
      "title": new FormControl(""),
      "pageSize": new FormControl(""),
      "page_num": new FormControl(""),
      "status": new FormControl(""),
      "from_date": new FormControl(""),
      "to_date": new FormControl(""),
      "index": new FormControl(""),
      "actiontype": new FormControl(""),
    });
  }

  formreset() {
    this.findform.reset();
    this.findform.controls['pageSize'].setValue(this.pageSize);
    this.findform.controls['page_num'].setValue(this.page_num);
    this.findform.controls['index'].setValue(this.index);
    this.getList(this.pageSize, this.page_num, this.index);
    this.getcount();
  }

  getList(pageSize, page_num, index) {
    this.findform.controls['pageSize'].setValue(pageSize);
    this.findform.controls['page_num'].setValue(page_num);
    this.findform.controls['index'].setValue(index);
    this.findform.controls['actiontype'].setValue('list');
    this.findform.value.sort = this.sort;
    this.findform.value.sortcolumn = this.sortcolumn;

    this.http.post('/admin/tonelist', this.findform.value, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res['status']) {
          this.recordlist = res['list'];
          this.index = res['index'];
        }
        else if (!res['status']) {
          this.router.navigate(['/dashboard']);
        }
      }, (err) => {
      });
  }

  getcount() {
    this.findform.controls['actiontype'].setValue('count');
    this.findform.value.sort = this.sort;
    this.findform.value.sortcolumn = this.sortcolumn;

    this.http.post('/admin/tonelist', this.findform.value, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res) {
          this.count = res['count'];
        } else {
          this.count = '0';
        }
      }, (err) => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
        }
      });
  }

  activestatus(id) {
    this.id1 = id
  }

  deleterecord(id) {
    this.deleteid = id
  }

  statuschange(id, status) {
    this.http.post('/admin/changetonestatus', { id: id, status: status, updated_by: this.userID, }, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res['status']) {
          this.getList(this.pageSize, this.page_num, this.index);
          this.getcount();
          this.success_message = "Status changed successfully.";
          setTimeout(() => {
            this.success_message = "";
          }, 2500);
        }
        else if (!res['status']) {
          this.error_message = "Status can't change.";
          setTimeout(() => {
            this.error_message = "";
          }, 2500);
        }
      }, (err) => {
      });
  }

  softdeleterecord(id) {
    this.http.post('/admin/softdeletetone', { id: id, updated_by: this.userID }, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {

        if (res['status']) {
          this.getList(this.pageSize, this.page_num, this.index);
          this.getcount();
          this.success_message = "Removed successfully ";
          setTimeout(() => {
            this.success_message = "";
          }, 2500);
        }
        else if (!res['status']) {
          this.error_message = "Something went wrong. please try again.";
          setTimeout(() => {
            this.error_message = "";
          }, 2500);
        }
      }, (err) => {
        setTimeout(() => {
          this.error_message = "";
        }, 2500);
      });
  }
}

