import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-add-edit-tutorial',
  templateUrl: './add-edit-tutorial.component.html',
  styleUrls: ['./add-edit-tutorial.component.css']
})
export class AddEditTutorialComponent implements OnInit {
  image_url_show = environment.image_url_show;
  isuserset: string;
  url: any;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  form: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  log: string = '';
  actionbutton: string = 'Add';
  pagetitle: string = 'Add';
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  vid_id: any;
  ImagePreivew: any = [];
  imageFile: File;
  imageErr: String;
  imageFileName: any;
  imageArray: Array<String> = [];
  Filetype: Array<String> = [];
  filtyp: any;
  filesToUpload: Array<File> = [];
  vid_image: string = '';
  src: String = "";
  fileToUpload: File = null;
  public imagePath;
  imgURL: any;
  fileToUploadArr: any = {};
  imgUrlArr: any = {};
  selected: any;
  imageNvideoValue: any;
  imageNvideoType: any;
  constructor(private userService: UserService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private titleService: Title) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
        /* code added by dev@av start */
        var usrAccessMenus = JSON.parse(JSON.stringify(userdata['menu_arr']));
        var operationpermissions = usrAccessMenus.find(elem => elem["id"] == '27');
        /* code added by dev@av end */
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
          if (this.url == 'add_tutorial' && !this.canadd) {
            this.router.navigate(['/tutorial']);
          }
          if (this.url == 'edit_tutorial' && !this.canedit) {
            this.router.navigate(['/tutorial']);
          }

        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })

    if (this.route.snapshot.params['id']) {
      this.vid_id = this.route.snapshot.params['id'];
    }
  }

  ngOnInit() {
    this.titleService.setTitle(this.pagetitle + ' ' + 'Tutorial - ' + environment.siteTitle);
    this.createForm();
    this.getdetail();
  }

  createForm() {
    this.form = new FormGroup({
      "page_url_title": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(100)

      ])),
      "vid_title": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(100)
      ])),

      "vid_long_disc": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(500)
      ])),
      "vid_short_disc": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(250)
      ])),
      "vid_status": new FormControl("", Validators.compose([
        Validators.required
      ])),
      "vid_type": new FormControl("", Validators.compose([
        Validators.required
      ])),

    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  imagesource(url) {
    var source = '';
    source = "assets/img/upload/" + url;
    this.src = source;
  }

  onFileSeleted(event, type = 'add', formKey = '') {
    if (type == 'add') {
      const files = event.target.files;
      const reader = new FileReader();
      this.imagePath = files;
      this.fileToUpload = files[0];
      reader.readAsDataURL(files[0]);
      reader.onload = evt => {
        this.imgURL = reader.result;
      };
      if (event.target.files && event.target.files[0]) {
        if (event.target.files[0].type === 'image/jpeg' ||
          event.target.files[0].type === 'image/png' ||
          event.target.files[0].type === 'image/jpg') {
          if (event.target.files[0].size < 2000000) {      // checking size here - 2MB           
          }
          else {
            this.imageErr = "File too large to upload(max 2MB)";
          }
        }
        else {
          this.imageErr = "File type should be jpg,jpeg,png";
        }
      }
    }
    else if (type == 'update') {
      if (formKey == '') {
        return false
      }
      const files = event.target.files;

      if (files.length > 0) {
        const reader = new FileReader();
        this.fileToUploadArr[formKey] = files[0];
        reader.readAsDataURL(files[0]);
        reader.onload = evt => {
          this.imgUrlArr[formKey] = reader.result;
        };
        if (this.fileToUploadArr[formKey]) {
          if (this.fileToUploadArr[formKey].type === 'image/jpeg' ||
            this.fileToUploadArr[formKey].type === 'image/png' ||
            this.fileToUploadArr[formKey].type === 'image/jpg') {
            if (this.fileToUploadArr[formKey].size < 2000000) {      // checking size here - 2MB           
            }
            else {
              this.imageErr = "File too large to upload(max 2MB)";
            }
          }
          else {
            this.imageErr = "File type should be jpg,jpeg,png";
          }
        }
      }
      else {
        delete this.fileToUploadArr[formKey]
        delete this.imgUrlArr[formKey]
      }
    }
  }

  fileChangeEvent(fileInput: any) {
    this.filesToUpload = <Array<File>>fileInput.target.files;
  }

  resettutorialImg() {
    this.imgUrlArr['editForm'] = '';
    this.vid_image = '';
    this.form.reset({
      page_url_title: '',
      vid_title: '',
      vid_path: '',
      vid_long_disc: '',
      vid_short_disc: '',
      vid_status: '',
      vid_type: '',
      vid_image: '',
    });
    this.ImagePreivew = [];

  }

  getdetail() {
    if (!this.route.snapshot.params['id']) {
      return;
    }
    this.actionbutton = 'Update';
    this.pagetitle = 'Edit';
    this.apiService.postData('admin/tutorial_detail', { vid_id: this.vid_id })
      .subscribe(res => {
        this.dataLoading = false;
        if (res['status']) {
          this.form.controls['page_url_title'].setValue(res['detail']['page_url_title']);
          this.form.controls['vid_title'].setValue(res['detail']['vid_title']);
          this.form.controls['vid_short_disc'].setValue(res['detail']['vid_short_disc']);
          this.form.controls['vid_long_disc'].setValue(res['detail']['vid_long_disc']);
          this.form.controls['vid_type'].setValue(res['detail']['vid_type']);

          this.form.controls['vid_status'].setValue(res['detail']['vid_status']);
          this.vid_id = res['detail']['vid_id']
          this.vid_image = res['detail']['vid_image'];
          this.imageNvideoType = res['detail']['vid_type'];

          if (this.form.value.vid_type == 0) {
            if (this.form.controls['vid_image']) {
              this.form.removeControl('vid_image');
            }
            this.form.addControl('vid_path', new FormControl('', Validators.required));
            this.form.controls['vid_path'].setValue(res['detail']['vid_path']);
          }
          else {
            if (this.form.controls['vid_path']) {
              this.form.removeControl('vid_path');
            }
            this.form.addControl('vid_image', new FormControl('', Validators.required));
            this.form.controls['vid_image'].setValue(this.vid_image);
          }
          this.titleService.setTitle(this.pagetitle + ' ' + 'Tutorial - ' + environment.siteTitle);
        } else {
          this.error_message = "Something went wrong !!";
          setTimeout(() => {
            this.router.navigate(['/tutorial'], { relativeTo: this.route });
          }, 3000);
        }
      }, (err) => {
        this.router.navigate(['/tutorial'], { relativeTo: this.route });
      });
  }

  changetype(event: any) {
    if (this.form.value.vid_type == 0) {
      this.form.removeControl('vid_image');
      this.form.addControl('vid_path', new FormControl('', Validators.required));
    }
    else {
      this.form.removeControl('vid_path');
      this.form.addControl('vid_image', new FormControl('', Validators.required));
    }
  }

  saveData() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    let formData = new FormData();


    if (this.actionbutton == 'Add') {
      const files: Array<File> = this.filesToUpload;
      for (let key in this.form.value) {
        formData.append(key, this.form.value[key]);
      }

      var filename = '';
      if (this.form.value.vid_type == 1) {
        if (files.length > 0) {
          for (var i = 0; i < files.length; i++) {
            filename = files[i].name;
            formData.append("vid_image", files[i], files[i]['name']);
          }
        }
        this.form.controls['vid_image'].setValue(filename == '' ? this.vid_image : filename);
      }

      if (this.form.valid) {
        this.http.post('/admin/create_tutorial', formData)
          .subscribe(res => {

            if (res['status']) {
              this.success_message = res['message'];
              setTimeout(() => {
                this.dataLoading = false;
                this.router.navigate(['/tutorial']);
                this.success_message = '';
              }, 2000);
            }
            else {
              this.error_message = res['message'];
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "Error occured. Please try again.";
            this.dataLoading = false;
            if (err.status == 401) {
              this.router.navigate(['/login']);
            }
          });
      } else {
        this.dataLoading = false;
        this.validateAllFormFields(this.form);
      }
    }
    if (this.actionbutton != 'Add') {
      this.form.addControl('vid_id', new FormControl(this.vid_id, Validators.required));
      const files: Array<File> = this.filesToUpload;
      for (let key in this.form.value) {
        formData.append(key, this.form.value[key]);
      }


      var filename = '';
      if (this.form.value.vid_type == 1) {
        if (files.length > 0) {
          for (var i = 0; i < files.length; i++) {
            filename = files[i].name;
            formData.append("vid_image", files[i], files[i]['name']);
          }
        }
        this.form.controls['vid_image'].setValue(filename == '' ? this.vid_image : filename);
      }

      if (this.form.valid) {

        this.http.post('/admin/edit_tutorial', formData)
          .subscribe(res => {
            if (res['status']) {
              this.success_message = res['message'];
              setTimeout(() => {
                this.dataLoading = false;
                this.router.navigate(['/tutorial']);
                this.success_message = '';
              }, 2000);
            }
            else {
              this.error_message = res['message'];
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "Error occured. Please try again.";
            this.dataLoading = false;
            if (err.status == 401) {
              this.router.navigate(['/login']);
            }
          });
      }
      else {
        this.dataLoading = false;
        this.validateAllFormFields(this.form);
      }
    }

  }

}





