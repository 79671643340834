import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Route } from '@angular/router';
import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
declare var $: any;

@Component({
  selector: 'app-manage-roles',
  templateUrl: './manage-roles.component.html',
  styleUrls: ['./manage-roles.component.css']
})

export class ManageRolesComponent implements OnInit {
  list: any;
  public dangerModal;
  public navItems = [];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public pageSize: number = 10;
  public page_num: number = 1;
  public index: number = 0;
  isuserset: string;
  userID: any;
  role: string;
  error_message: String;
  success_message: String;
  fullname: String;
  email: String;
  type: String;
  filterform: FormGroup;
  count: String;
  statustype: String;
  roleid: Number;
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  sortcolumn: any = '';
  sort: any = 'DESC';

  homeHeaderData = { 'userName': null, 'menu_list': null };
  constructor(private userService: UserService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private titleService: Title) {
    this.userService.getuser().subscribe(userdata => {

      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.menu_list = userdata['menu_list'];
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));

        var operationpermissions = this.navItems.find(elem => elem["id"] == '2');
        operationpermissions = operationpermissions['children'];
        operationpermissions = operationpermissions.find(element => element["id"] == '4');
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
    },
      error => {
        this.router.navigate(['/login']);
      })
  }




  ngOnInit() {
    this.titleService.setTitle('Manage Roles- ' + environment.siteTitle);
    this.createForm();
    this.getlist(this.pageSize, this.page_num, this.index);
    this.getcount();
  }
  sortby(column_name) {


    if (this.sortcolumn != column_name) {
      this.sort = 'ASC'
      this.sortcolumn = column_name
    }
    else {
      this.sort = this.sort == 'DESC' ? 'ASC' : 'DESC'
    }


    this.getlist(this.pageSize, this.page_num, this.index);
    this.getcount();
  }
  createForm() {
    this.filterform = new FormGroup({
      "title": new FormControl(""),
      "status": new FormControl(""),
      "pageSize": new FormControl(""),
      "page_num": new FormControl(""),
      "index": new FormControl(""),
      "actiontype": new FormControl(""),
    });
  }

  getcount() {

    this.filterform.controls['actiontype'].setValue('count');
    this.filterform.value.sortcolumn = this.sortcolumn;
    this.filterform.value.sort = this.sort;

    this.http.post('/admin/manage_roles', this.filterform.value, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res['status']) {
          this.count = res['count'];
        } else {
          this.count = '0';
        }

      }, (err) => {

      });
  }

  getlist(pageSize, page_num, index) {
    this.error_message = '';

    this.filterform.controls['pageSize'].setValue(pageSize);
    this.filterform.controls['page_num'].setValue(page_num);
    this.filterform.controls['index'].setValue(index);
    this.filterform.controls['actiontype'].setValue('list');
    this.filterform.value.sortcolumn = this.sortcolumn;
    this.filterform.value.sort = this.sort;

    this.http.post('/admin/manage_roles', this.filterform.value, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {

        if (res['status']) {
          this.list = res['list'];

          this.index = res['index'];
        } else {
          this.list = [];
        }
      }, (err) => {
        if (err.status == 500) {
          this.router.navigate(['/dashboard']);
        } else {

        }
      });
  }

  resetform() {
    this.filterform.reset();
    this.pageSize = 10;
    this.page_num = 1;
    this.index = 0;
    this.filterform.controls['pageSize'].setValue(this.pageSize);
    this.filterform.controls['page_num'].setValue(this.page_num);
    this.filterform.controls['index'].setValue(this.index);
    this.getlist(this.pageSize, this.page_num, this.index)
    this.getcount();
  }

  recordSet(id, statustype) {
    this.roleid = id;
    this.statustype = statustype;
  }

  updateStatus() {
    this.success_message = "";
    this.error_message = "";
    if (this.roleid && this.statustype && this.statustype != 'Remove') {
      this.http.post('/admin/change_role_status', { id: this.roleid, statustype: this.statustype, updated_by: this.userID }, {
        withCredentials: true,
        headers: new HttpHeaders().append('Content-Type', 'application/json')
      })
        .subscribe(res => {
          this.getlist(this.pageSize, this.page_num, this.index)
          this.getcount();
          this.roleid = 0;
          this.statustype = '';
          this.success_message = 'Status updated successfully.';
          setTimeout(() => {
            this.success_message = "";
          }, 2500);
        }, (err) => {

          this.roleid = 0;
          this.statustype = '';
          this.error_message = 'Something went wrong.';
          setTimeout(() => {
            this.error_message = "";
          }, 1500);
        });
    }
  }

  removeRecord() {
    this.success_message = "";
    this.error_message = "";
    if (this.roleid && this.statustype && this.statustype == 'Remove') {
      this.http.post('/admin/remove_role', { id: this.roleid, updated_by: this.userID }, {
        withCredentials: true,
        headers: new HttpHeaders().append('Content-Type', 'application/json')
      })
        .subscribe(res => {
          if (res['status']) {
            this.getlist(this.pageSize, this.page_num, this.index)
            this.getcount();
            this.roleid = 0;
            this.statustype = '';
            this.success_message = 'Role removed successfully.';
            setTimeout(() => {
              this.success_message = "";
            }, 2500);
          } else {
            this.roleid = 0;
            this.statustype = '';
            this.error_message = res['message'];
            setTimeout(() => {
              this.error_message = "";
            }, 2500);
          }

        }, (err) => {

          this.roleid = 0;
          this.statustype = '';
          this.error_message = 'Something went wrong.';
          setTimeout(() => {
            this.error_message = "";
          }, 1500);
        });
    }
  }

}