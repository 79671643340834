import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService {
    constructor(private http: HttpClient) { }
    // ...
    // public isAuthenticated(): string {
    //     (async () => {
    //         
    //     })

    // }

    isAuthenticated(callback) {
        this.http.post('/admin/authenticateuser', {
            withCredentials: true,
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        }).subscribe(res => {
            if(res['status']){
                callback({status:true,data:res['message']});
            }
            else{
                callback({status:false,data:null});
            }
        },
            err => {
                callback({status:false,data:err});
            })
    }
}