import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Router, UrlTree } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ActivatedRoute, Route } from '@angular/router';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
declare var $: any;

@Component({
  selector: 'app-create-roles',
  templateUrl: './create-roles.component.html',
  styleUrls: ['./create-roles.component.css']
})
export class CreateRolesComponent implements OnInit {
  public navItems = [];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  isuserset: string;
  userID: any;
  url: any;
  email: string;
  role: string;
  last_login: string;
  addform: FormGroup;
  success_message: String;
  error_message: String;
  dataLoading: boolean;
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any = [];
  homeHeaderData = { 'userName': null, 'menu_list': null };
  constructor(private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private titleService: Title,
  ) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);

      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];

      if (userdata['type'] != 'superadmin') {
        this.navItems = userdata['menu_list'];
        var operationpermissions = JSON.parse(JSON.stringify(this.navItems.find(elem => elem["id"] == '2')));
        if (typeof (operationpermissions) != 'undefined') {
          operationpermissions = operationpermissions['children'];
          operationpermissions = operationpermissions.find(element => element["id"] == '4');
          if (typeof (operationpermissions) != 'undefined') {
            if (!operationpermissions['view']) {
              this.router.navigate(['/dashboard']);
            }
            this.canadd = operationpermissions['add_p'];
            this.canedit = operationpermissions['edit_p'];
            this.candelete = operationpermissions['delete_p'];

            if (this.url == 'create_roles' && !this.canadd) {
              this.router.navigate(['/manage_roles']);
            }

          } else {
            this.router.navigate(['/dashboard']);
          }
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };

    },
      error => {
        this.router.navigate(['/login']);
      })
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized')
    });
    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }

  ngOnInit() {

    this.titleService.setTitle('Create Role - ' + environment.siteTitle);
    this.createForm();
    this.getMenuList();
  }
  viewchecked(id) {
    if ($("#" + id).is(':checked')) {
      $("." + id).removeAttr('disabled');
    } else {
      $("." + id).prop('checked', false);
      $("." + id).attr('disabled', true);
      if ($("." + id).hasClass("sub" + id)) {
        var subid = $(".sub" + id).attr('id');
        $(".sub" + id).prop('checked', false);
        $(".sub" + id).attr('disabled', true);
        $("." + subid).prop('checked', false);
        $("." + subid).attr('disabled', true);
      }
    }
  }
  ngAfterViewInit() {

  }
  createForm() {
    this.addform = new FormGroup({
      "title": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(50)
      ])),
      "status": new FormControl("", Validators.compose([
        Validators.required
      ])),
      "created_by": new FormControl(""),
    })
  }

  getMenuList() {
    this.http.post('/admin/get_admin_menu', {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res['status']) {
          this.menu_list = res['list'];
        } else {
          this.menu_list = [];
        }
      }, (err) => {

      });
  }
  submitRecord() {
    this.error_message = "";
    this.success_message = "";
    if (this.addform.valid) {
      this.dataLoading = true;
      this.addform.controls['created_by'].setValue(this.userID);
      var record_arr = [];
      $("input[name='menus[]'").map(function (i, el) {
        var menuid = $(this).val();
        var view = $("input[name='view[" + menuid + "]'").is(":checked");
        var add = $("input[name='add[" + menuid + "]'").is(":checked");
        var edit = $("input[name='edit[" + menuid + "]'").is(":checked");
        var remove = $("input[name='remove[" + menuid + "]'").is(":checked");
        record_arr.push({
          menu_id: menuid,
          view: view,
          add: add,
          edit: edit,
          remove: remove
        });

      });
      var formdata = {


        title: this.addform.controls['title'].value,
        status: this.addform.controls['status'].value,
        created_by: this.userID,
        record_arr: record_arr
      };
      if ($(".viewrow:checked").length == 0) {
        this.error_message = "Please set role permissions.";
        this.dataLoading = false;
        setTimeout(() => {
          this.error_message = "";
        }, 2000);
      } else {
        this.http.post('/admin/add_role', formdata, {
          withCredentials: true,
          headers: new HttpHeaders().append('Content-Type', 'application/json')
        })
          .subscribe(res => {
            if (res) {
              if (res['status']) {
                this.success_message = res['message'];
                setTimeout(() => {
                  this.router.navigate(['/manage_roles'], { relativeTo: this.route });
                }, 1000);
              }
              else {
                this.error_message = res['message'];
                this.dataLoading = false;
                setTimeout(() => {
                  this.error_message = "";
                }, 3000);
              }
            }
          }, (err) => {
            this.dataLoading = false;
            this.error_message = "Error occured. please try again.";
          });
      }
    }
    else {
      this.validateAllFormFields(this.addform);
      this.dataLoading = false;
    }
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
