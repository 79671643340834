import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../api.service';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-project-general-settings',
  templateUrl: './project-general-settings.component.html',
  styleUrls: ['./project-general-settings.component.css']
})
export class ProjectGeneralSettingsComponent implements OnInit {
  navItems : any = [];
  isuserset: string;
  userID: any;
  role: string;
  pageSize: number = 10;
  page_num: number = 1;
  index: number = 0;
  findform: FormGroup;
  id1: String;
  deleteid: String;
  count: String;
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  recordList: any = [];
  sortcolumn: any = '';
  sort: any = 'DESC';
  error_message: String;
  success_message: String;
  homeHeaderData = { 'userName': null, 'menu_list': null };
  constructor(
              private userService: UserService,
              private router: Router,
              private http: HttpClient,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private titleService: Title
              ) {
                this.userService.getuser().subscribe(userdata => {
                  this.userID = userdata['id'];
                  this.isuserset = userdata['fullname'];
                  this.role = userdata['type'];
                  this.menu_list = userdata['menu_list'];
                  this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
                  if (userdata['type'] != 'superadmin') {
                    this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
                    var operationpermissions = this.navItems.find(elem => elem["id"] == '7');
                    if (typeof (operationpermissions) != 'undefined') {
                      if (!operationpermissions['view']) {
                        this.router.navigate(['/dashboard']);
                      }
                      this.canadd = operationpermissions['add_p'];
                      this.canedit = operationpermissions['edit_p'];
                      this.candelete = operationpermissions['delete_p'];
                    } else {
                      this.router.navigate(['/dashboard']);
                    }
                  }
                  else {
                    this.canadd = true;
                    this.canedit = true;
                    this.candelete = true;
                  }
                },
                  error => {
                    this.router.navigate(['/login']);
                  })
               }

  ngOnInit() {
    this.titleService.setTitle('Manage Event- ' + environment.siteTitle);
    this.createForm();
    this.getList(this.pageSize, this.page_num, this.index);
    this.getcount();
  }

  sortby(column_name) {

    if (this.sortcolumn != column_name) {
      this.sort = 'ASC'
      this.sortcolumn = column_name
    }
    else {
      this.sort = this.sort == 'DESC' ? 'ASC' : 'DESC'
    }


    this.recordList(this.pageSize, this.page_num, this.index);
    this.getcount();
  }

  createForm() {
    this.findform = new FormGroup({
      "general_type": new FormControl(""),
      "setting_type": new FormControl(""),
      "pageSize": new FormControl(""),
      "page_num": new FormControl(""),
      "status": new FormControl(""),
      "index": new FormControl(""),
      "actiontype": new FormControl(""),
    });
  }

  resetForm() {
    this.findform.reset();
    this.findform.controls['pageSize'].setValue(this.pageSize);
    this.findform.controls['page_num'].setValue(this.page_num);
    this.findform.controls['index'].setValue(this.index);
    this.getList(this.pageSize, this.page_num, this.index);
    this.getcount();
  }

  getList(pageSize, page_num, index) {
    this.findform.controls['pageSize'].setValue(pageSize);
    this.findform.controls['page_num'].setValue(page_num);
    this.findform.controls['index'].setValue(index);
    this.findform.controls['actiontype'].setValue('list');
    this.findform.value.sortcolumn = this.sortcolumn
    this.findform.value.sort = this.sort
    this.http.post('/admin/projectgeneralsettings', this.findform.value, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res['status']) {
          this.recordList = res['list'];
          this.index = res['index'];
        }
        else if (!res['status']) {
          this.router.navigate(['/dashboard']);
        }
      }, (err) => {

      });
  }

  getcount() {
    this.findform.controls['actiontype'].setValue('count');
    this.findform.value.sortcolumn = this.sortcolumn
    this.findform.value.sort = this.sort
    this.http.post('/admin/projectgeneralsettings', this.findform.value, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res) {
          this.count = res['count'];
        } else {
          this.count = '0';
        }
      }, (err) => {

        if (err.status == 401) {
          this.router.navigate(['/login']);
        }
      });
  }

  activestatus(id) {
    console.log(id)
    this.id1 = id
  }

  statuschange(id, status) {
    console.log(this.id1,status)
    this.http.post('/admin/change-general-settings-status', { id: id, status: status, updated_by: this.userID, }, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res['status']) {
          this.getList(this.pageSize, this.page_num, this.index);
          this.getcount();
          this.success_message = "Event status changed successfully.";
          setTimeout(() => {
            this.success_message = "";
          }, 2500);
        }
        else if (!res['status']) {
          this.error_message = "Event status can't change.";
          setTimeout(() => {
            this.error_message = "";
          }, 2500);
        }
      }, (err) => {

      });
  }

}
