import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
declare var $: any;

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})

export class ManageComponent implements OnInit {
  public navItems = [];
  isuserset: string;
  userID: any;
  email1: string;
  role: string;
  public pageSize: number = 10;
  public page_num: number = 1;
  public index: number = 0;
  findform: FormGroup;
  adminlist: any = [];
  id1: String;
  deleteid: String;
  count: String;
  rolelist: any;
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  error_message: String;
  success_message: String;
  sortcolumn: any = '';
  sort: any = 'DESC';
  homeHeaderData = { 'userName': null, 'menu_list': null };
  constructor(private userService: UserService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private titleService: Title) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email1 = userdata['email1'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.menu_list = userdata['menu_list'];
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
        var operationpermissions = this.navItems.find(elem => elem["id"] == '2');
        operationpermissions = operationpermissions['children'];
        operationpermissions = operationpermissions.find(element => element["id"] == '3');
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
    },
      error => {
        this.router.navigate(['/login']);
      })
    this.http.post('/admin/get_roles', {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res) {
          this.rolelist = res['list'];
        } else {

        }
      }, (err) => {

      });
  }

  ngOnInit() {
    this.titleService.setTitle('Manage Admin- ' + environment.siteTitle);
    this.createForm();
    this.getadminlist(this.pageSize, this.page_num, this.index);
    this.getcount();
    var script = document.createElement('script');
    script.src = '../../assets/js/bootstrap-datepicker.min.js';
    var head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
  }
  sortby(column_name) {

    if (this.sortcolumn != column_name) {
      this.sort = 'ASC'
      this.sortcolumn = column_name
    }
    else {
      this.sort = this.sort == 'DESC' ? 'ASC' : 'DESC'
    }
    this.getadminlist(this.pageSize, this.page_num, this.index);
    this.getcount();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      var formvl = this.findform;
      $('#datetimepicker1').datepicker({
        format: "dd/mm/yyyy",
        autoclose: true,
      }).on('changeDate', function (ev) {
        var dateObject = $(this).datepicker("getDate");
        var d = dateObject.getDate();
        if (dateObject.getDate() < 10) {
          d = '0' + dateObject.getDate();
        }
        var dateString = dateObject.getFullYear() + '-' + (dateObject.getMonth() + 1) + '-' + d;
        formvl.controls['from_date'].setValue(dateString);
        $(this).datepicker('hide');
      });
      $('#datetimepicker2').datepicker({
        format: "dd/mm/yyyy",
        autoclose: true,
        gotoCurrent: true,
        maxDate: "+1d",
      }).on('changeDate', function (ev) {
        var dateObject = $(this).datepicker("getDate");
        var d = dateObject.getDate();
        if (dateObject.getDate() < 10) {
          d = '0' + dateObject.getDate();
        }
        var dateString = dateObject.getFullYear() + '-' + (dateObject.getMonth() + 1) + '-' + d;
        formvl.controls['to_date'].setValue(dateString);
        $(this).datepicker('hide');
      });
    }, 2000);
  }

  createForm() {
    this.findform = new FormGroup({
      "fullname": new FormControl(""),
      "email": new FormControl(""),
      "role_id": new FormControl(""),
      "pageSize": new FormControl(""),
      "page_num": new FormControl(""),
      "status": new FormControl(""),
      "from_date": new FormControl(""),
      "to_date": new FormControl(""),
      "index": new FormControl(""),
      "actiontype": new FormControl(""),
    });
  }

  adminformreset() {
    this.findform.reset();
    this.findform.controls['pageSize'].setValue(this.pageSize);
    this.findform.controls['page_num'].setValue(this.page_num);
    this.findform.controls['index'].setValue(this.index);
    this.getadminlist(this.pageSize, this.page_num, this.index);
    this.getcount();
  }

  getadminlist(pageSize, page_num, index) {
    this.findform.controls['pageSize'].setValue(pageSize);
    this.findform.controls['page_num'].setValue(page_num);
    this.findform.controls['index'].setValue(index);
    this.findform.controls['actiontype'].setValue('list');
    this.findform.value.sortcolumn = this.sortcolumn;
    this.findform.value.sort = this.sort;
    this.http.post('/admin/adminlist', this.findform.value, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res['status']) {
          var templist = [];
          var resultlist = [];
          var rolelist = [];
          templist = res['response'];
          if (templist.length > 0) {
            this.http.post('/admin/get_roles', {
              withCredentials: true,
              headers: new HttpHeaders().append('Content-Type', 'application/json')
            })
              .subscribe(res => {
                if (res) {
                  rolelist = res['list'];
                  for (let index = 0; index < templist.length; index++) {
                    const element = templist[index];
                    var role = rolelist.find(elem => elem.id == element.role_id);
                    var rolename = '';
                    if (typeof (role) != 'undefined') {
                      rolename = role['title'];
                    }
                    var result = {
                      id: element.id,
                      fullname: element.fullname,
                      email: element.email,
                      role_name: rolename,
                      status: element.status,
                      created_date: element.created_date,
                    };
                    resultlist.push(result);
                  }
                  this.adminlist = resultlist;
                } else {

                }
              }, (err) => {

              });

          }
          else {
            this.adminlist = [];
          }
        }
        else if (!res['status']) {
          this.router.navigate(['/dashboard']);
        }
        this.index = res['index']
      }, (err) => {

      });
  }

  getcount() {
    this.findform.controls['actiontype'].setValue('count');
    this.findform.value.sortcolumn = this.sortcolumn;
    this.findform.value.sort = this.sort;
    this.http.post('/admin/adminlist', this.findform.value, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res) {
          this.count = res['count'];
        } else {
          this.count = '0';
        }

      }, (err) => {

      });
  }

  activestatus(id) {
    this.id1 = id
  }
  deleteadmin(id) {
    this.deleteid = id
  }

  statuschange(id, status) {
    this.http.post('/admin/changeadminstatus', { id: id, status: status, updated_by: this.userID }, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res['status']) {
          this.getadminlist(this.pageSize, this.page_num, this.index);
          this.getcount();
        }
        else if (!res['status']) {
          this.router.navigate(['/dashboard']);
        }

      }, (err) => {

      });
  }
  softdeleteadmin(id) {
    this.http.post('/admin/softdeleteadmin', { id: id, updated_by: this.userID }, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res['status']) {
          this.getadminlist(this.pageSize, this.page_num, this.index);
          this.getcount();
          this.success_message = 'Admin user removed successfully.';
          setTimeout(() => {
            this.success_message = "";
          }, 2500);
        }
        else if (!res['status']) {
          this.router.navigate(['/dashboard']);
          this.error_message = res['message'];
          setTimeout(() => {
            this.error_message = "";
          }, 2500);
        }
      }, (err) => {

      });
  }
}
