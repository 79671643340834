import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';
import { ThrowStmt } from '@angular/compiler';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  isuserset: string = '';
  role: string = '';
  public navItems: any = [];
  @Input() headerData: any;
  second_dashboard: boolean = false;
  superadmin: boolean = false;
  status: boolean = false;
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  type: any;
  homeHeaderData = { 'userName': null, 'menu_list': null };
  userID: any;
  email: string;

  last_login: string;
  constructor(private userService: UserService, private http: HttpClient, private cookieService: CookieService, private router: Router, private route: ActivatedRoute) {


  }

  ngOnInit() {
    this.isuserset = this.headerData['userName'];
    this.navItems = this.headerData['menu_list'];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isuserset = changes.headerData.currentValue['userName'];
    this.navItems = changes.headerData.currentValue['menu_list'];

  }

  toggleclass(id) {
    if (!$("#" + id).hasClass('active')) {
      $("#" + id).addClass(['active', 'menu-open'])
    }
    else {
      $("#" + id).removeClass(['active', 'menu-open'])
    }
  }

  logout() {
    this.http.post('/admin/logout', {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res) {
          this.cookieService.delete('userid');
          this.cookieService.delete('id');
          this.cookieService.deleteAll();
          setTimeout(() => {
            this.router.navigate(['/login'], { relativeTo: this.route });
          }, 1000);
        } else {
          this.router.navigate(['/login'], { relativeTo: this.route });
        }
      }, (err) => {

        this.router.navigate(['/login'], { relativeTo: this.route });
      }
      );
  }
}
