import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';


@Component({
  selector: 'app-scene-builder-description',
  templateUrl: './scene-builder-description.component.html',
  styleUrls: ['./scene-builder-description.component.css']
})
export class SceneBuilderDescriptionComponent implements OnInit {

  isuserset: string;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  form: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  buttontext: String = 'Add';
  pagetitle: String = 'Add'
  id: any;
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  constructor(private userService: UserService,
    private router: Router,
    private apiService: ApiService,
    private titleService: Title,
    private route: ActivatedRoute, ) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
        /* code added by dev@av start */
        var usrAccessMenus = JSON.parse(JSON.stringify(userdata['menu_arr']));
        var operationpermissions = usrAccessMenus.find(elem => elem["id"] == '11');
        /* code added by dev@av end */
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];

        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })

  }
  ngOnInit() {
    this.titleService.setTitle('Scene Builder description- ' + environment.siteTitle);
    this.createForm();
    this.getdetail();
  }
  createForm() {
    this.form = new FormGroup({
      "description": new FormControl("", Validators.compose([
        Validators.required,

      ])),
    })
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  getdetail() {
    this.apiService.postData('admin/scene_builder_description')
      .subscribe(res => {
        this.dataLoading = false;
        if (res['status']) {

          this.form.controls['description'].setValue(res['detail']['description'])
          this.id = res['detail']['id']
        } else {
          this.error_message = "Something went wrong !!";

        }
      }, (err) => {

      });

  }
  Updatedata() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    this.form.addControl('modified_by', new FormControl(this.userID, Validators.required));
    this.form.addControl('id', new FormControl(this.id, Validators.required));
    if (this.form.valid) {
      this.apiService.postData('admin/update_scene_builder_description', this.form.value)
        .subscribe(res => {
          if (res['status']) {
            this.dataLoading = false;
            this.success_message = 'Description updated successfully';
            setTimeout(() => {
              this.success_message = '';
            }, 3000);
          }
          else {
            this.error_message = "Description can't updated";
            this.dataLoading = false;
          }
        }, (err) => {
          this.error_message = "Description can't updated";
          this.dataLoading = false;
        });
    }
    else {

      this.dataLoading = false;
      this.validateAllFormFields(this.form);
    }

  }
}
