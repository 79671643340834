import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router, UrlTree } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-edit-tone-option',
  templateUrl: './edit-tone-option.component.html',
  styleUrls: ['./edit-tone-option.component.css']
})
export class EditToneOptionComponent implements OnInit {


  templatelist: any;
  url: any;
  title: any;
  title1: any;
  ckeConfig: any;
  isuserset: string;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  tonesform: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  name = 'ng2-ckeditor';
  movements: any = [];
  titlelist: any = [];
  log: string = '';
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;

  imageErr: String;
  imageFile: File;
  imageFileName: any;
  imageArray: Array<String> = [];
  Filetype: Array<String> = [];
  filtyp: any;
  filesToUpload: Array<File> = [];
  event_id: any;
  id1: any;
  id: any
  buttontext: String = 'Add';
  pagetitle: String = 'Add'
  ImagePreivew: any = [];
  imagestatus: boolean = false;
  image_url_show = environment.image_url_show;
  src: String = "";

  tone_image: any = [];
  titlelist1: any = [];

  resetimg: any;
  fileToUploadArr: any = {};
  imgUrlArr: any = {};
  tones_id: any;
  constructor(private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private apiService: ApiService,
    private titleService: Title,
    private route: ActivatedRoute, ) {
    this.userService.getuser().subscribe(userdata => {

      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];

      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));

        /* code added by dev@av start */
        var usrAccessMenus = JSON.parse(JSON.stringify(userdata['menu_arr']));
        var operationpermissions = usrAccessMenus.find(elem => elem["id"] == '15');
        /* code added by dev@av end */
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];

          if (this.url == 'edit_tone_option' && !this.canedit) {
            this.router.navigate(['/tone_option']);
          }

        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })
    this.id = this.route.snapshot.params['id'];


  }

  ngOnInit() {
    this.titleService.setTitle('Edit Tone Option- ' + environment.siteTitle);
    this.createForm()
    this.get_title()
    this.getdetail();

  }


  onChange1($event: any): void {
  }

  createForm() {
    this.tonesform = new FormGroup({

      "option": new FormControl("", Validators.compose([
        Validators.required
      ])),

      "tones_question_id": new FormControl("", Validators.compose([
        Validators.required
      ])),

      "tone_image": new FormControl("", Validators.compose([
        Validators.required
      ])),

      "is_active": new FormControl("", Validators.compose([
        Validators.required
      ])),

    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  get_title() {

    this.apiService.postData('admin/get_option_tone', {})
      .subscribe(res => {

        if (res['status']) {
          this.titlelist = res['list']


        }
      }, (err) => {

      });
  }
  getdetail() {
    if (this.route.snapshot.params['id']) {
      this.buttontext = "Update"
      this.pagetitle = "Edit"
      this.apiService.postData('admin/tones_option_detail', { id: this.id })
        .subscribe(res => {

          this.dataLoading = false;
          if (res['status']) {
            this.tonesform.addControl('tones_id', new FormControl(res['detail']['tones_id'], Validators.required));

            this.tonesform.controls['tones_question_id'].setValue(res['detail']['tones_question_id']);
            this.tonesform.controls['is_active'].setValue(res['detail']['is_active']);
            this.tonesform.controls['option'].setValue(res['detail']['option']);
            this.tone_image = res['detail']['image'];
            this.tones_id = res['detail']['tones_id'];
            if (this.tone_image && this.tone_image != null) {
              this.tonesform.controls['tone_image'].setValue(this.tone_image);
            }


          } else {
            this.error_message = "Something went wrong !!";
            setTimeout(() => {
              this.router.navigate(['/tone_option']);
            }, 3000);
          }
        }, (err) => {

          this.router.navigate(['/tone_option']);
        });
    }
  }


  saveData() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    this.tonesform.controls['tones_id'].setValue(this.tones_id);
    this.tonesform.addControl('tones_id', new FormControl(this.tones_id, Validators.required));
    this.tonesform.addControl('id', new FormControl(this.id, Validators.required));

    if (this.tonesform.valid) {

      let formData = new FormData();
      const files: Array<File> = this.filesToUpload;
      delete this.tonesform.value.tone_image;


      for (let key in this.tonesform.value) {
        formData.append(key, this.tonesform.value[key]);

      }

      if (files.length > 0) {

        formData.append("image", files[0], files[0]['name']);

      } else {

        formData.append("image", this.tone_image);

      }

      this.http.post('/admin/edit_tone_option', formData)

        .subscribe(res => {

          if (res['status']) {
            this.success_message = res['message'];
            setTimeout(() => {
              this.dataLoading = false;

              this.router.navigate(['/tone_option']);
              this.success_message = '';
            }, 2000);
          }
          else {
            this.error_message = res['message'];
            this.dataLoading = false;
          }
        }, (err) => {
          this.error_message = "Error occured. please try again.";
          this.dataLoading = false;
          if (err.status == 401) {
            this.router.navigate(['/login']);
          }
        });
    } else {
      this.dataLoading = false;
      this.validateAllFormFields(this.tonesform);

    }
  }

  imagesource(url) {
    var source = '';
    source = "assets/img/upload/" + url;
    this.src = source;
  }

  onFileSeleted(event, type = 'update', formKey = '') {
    if (type == 'update') {
      if (formKey == '') {
        return false
      }
      const files = event.target.files;

      if (files.length > 0) {
        const reader = new FileReader();
        this.fileToUploadArr[formKey] = files[0];
        reader.readAsDataURL(files[0]);
        reader.onload = evt => {
          this.imgUrlArr[formKey] = reader.result;
        };
        if (this.fileToUploadArr[formKey]) {
          if (this.fileToUploadArr[formKey].type === 'image/jpeg' ||
            this.fileToUploadArr[formKey].type === 'image/png' ||
            this.fileToUploadArr[formKey].type === 'image/jpg') {
            if (this.fileToUploadArr[formKey].size < 2000000) {      // checking size here - 2MB           
            }
            else {
              this.imageErr = "File too large to upload(max 2MB)";
            }
          }
          else {
            this.imageErr = "File type should be jpg,jpeg,png";
          }
        }
      }
      else {
        delete this.fileToUploadArr[formKey]
        delete this.imgUrlArr[formKey]
      }

    }

  }

  fileChangeEvent(fileInput: any) {
    this.filesToUpload = <Array<File>>fileInput.target.files;
  }

  resetToneImg() {
    this.imgUrlArr['editForm'] = '';
    this.tone_image = '';
    this.tonesform.reset({
      tones_id: '',
      option: '',
      tones_question_id: '',
      tone_image: '',
      is_active: '',
    });

  }

}















