import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';
@Component({
  selector: 'app-themeaddedit',
  templateUrl: './themeaddedit.component.html',
  styleUrls: ['./themeaddedit.component.css']
})
export class ThemeaddeditComponent implements OnInit {
  isuserset: string;
  url: any;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  form: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  log: string = '';
  actionbutton: string = 'Add';
  pagetitle: string = 'Add';
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  edit_id: any;
  ImagePreivew: any = [];
  constructor(private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private titleService: Title) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
        /* code added by dev@av start */
        var usrAccessMenus = JSON.parse(JSON.stringify(userdata['menu_arr']));
        var operationpermissions = usrAccessMenus.find(elem => elem["id"] == '17');
        /* code added by dev@av end */
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
          if (this.url == 'create_theme' && !this.canadd) {
            this.router.navigate(['/manage_theme']);
          }
          if (this.url == 'edit_theme' && !this.canedit) {
            this.router.navigate(['/manage_theme']);
          }

        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })

    if (this.route.snapshot.params['id']) {
      this.edit_id = this.route.snapshot.params['id'];
    }
  }

  ngOnInit() {
    this.titleService.setTitle(this.pagetitle + ' ' + 'Theme - ' + environment.siteTitle);
    this.createForm();
    this.getdetail();
  }

  createForm() {
    this.form = new FormGroup({
      "title": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(100)
      ])),
      "sort_description": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(500)
      ])),
      "status": new FormControl("", Validators.compose([
        Validators.required
      ])),
    })
  }

  getdetail() {
    if (!this.route.snapshot.params['id']) {
      return;
    }
    this.actionbutton = 'Update';
    this.pagetitle = 'Edit';
    this.apiService.postData('admin/theme_detail', { id: this.edit_id })
      .subscribe(res => {
        this.dataLoading = false;
        if (res['status']) {
          this.form.controls['title'].setValue(res['detail']['title']);
          this.form.controls['sort_description'].setValue(res['detail']['sort_description']);
          this.form.controls['status'].setValue(res['detail']['status']);
          this.titleService.setTitle(this.pagetitle + ' ' + 'Theme - ' + environment.siteTitle);
        } else {
          this.error_message = "Something went wrong !!";
          setTimeout(() => {
            this.router.navigate(['/manage_theme'], { relativeTo: this.route });
          }, 3000);
        }
      }, (err) => {
        this.router.navigate(['/manage_theme'], { relativeTo: this.route });
      });
  }

  saveData() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    if (this.actionbutton == 'Add') {
      if (this.form.valid) {
        this.apiService.postData('admin/create_theme', this.form.value)
          .subscribe(res => {
            if (res['status']) {
              this.success_message = res['message'];
              setTimeout(() => {
                this.dataLoading = false;

                this.router.navigate(['/manage_theme']);
                this.success_message = '';
              }, 2000);
            }
            else {
              this.error_message = res['message'];
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "Error occured. please try again.";
            this.dataLoading = false;
            if (err.status == 401) {
              this.router.navigate(['/login']);
            }
          });
      } else {
        this.dataLoading = false;
        this.validateAllFormFields(this.form);
      }
    }
    if (this.actionbutton != 'Add') {
      if (this.form.valid) {
        this.form.addControl('id', new FormControl(this.edit_id, Validators.required));
        this.apiService.postData('admin/edit_theme', this.form.value)
          .subscribe(res => {
            if (res['status']) {
              this.success_message = res['message'];
              setTimeout(() => {
                this.dataLoading = false;
                this.router.navigate(['/manage_theme']);
                this.success_message = '';
              }, 2000);
            }
            else {
              this.error_message = res['message'];
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "Error occured. please try again.";
            this.dataLoading = false;
            if (err.status == 401) {
              this.router.navigate(['/login']);
            }
          });
      } else {
        this.dataLoading = false;
        this.validateAllFormFields(this.form);
      }
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}