import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
declare var $: any;
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
declare var $: any;

@Component({
  selector: 'app-manage-stories',
  templateUrl: './manage-stories.component.html',
  styleUrls: ['./manage-stories.component.css']
})
export class ManageStoriesComponent implements OnInit {

  public navItems = [];
  isuserset: string;
  userID: any;
  email1: string;
  role: string;
  public pageSize: number = 10;
  public page_num: number = 1;
  public index: number = 0;
  findform: FormGroup;
  projectlist: any = [];
  id1: String;
  deleteid: String;
  error_message: String;
  success_message: String;
  count: String;
  rolelist: any;
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  sortcolumn: any = '';
  sort: any = 'DESC';
  homeHeaderData = { 'userName': null, 'menu_list': null };
  html: string = `<span class="btn btn-warning">Never trust not sanitized <code>HTML</code>!!!</span>`;
  EXCEL_EXTENSION = '.xlsx';
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  constructor(private userService: UserService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private titleService: Title) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email1 = userdata['email1'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.menu_list = userdata['menu_list'];
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
        var operationpermissions = this.navItems.find(elem => elem["id"] == '9');
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
    },
      error => {
        this.router.navigate(['/login']);
      })
  }

  ngOnInit() {
    this.titleService.setTitle('Manage Stories- ' + environment.siteTitle);
    this.createForm();
    this.getprojectlist(this.pageSize, this.page_num, this.index);
    this.getcount();
    var script = document.createElement('script');
    script.src = '../../assets/js/bootstrap-datepicker.min.js';
    var head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
  }
  sortby(column_name) {
    if (this.sortcolumn != column_name) {
      this.sort = 'ASC'
      this.sortcolumn = column_name
    }
    else {
      this.sort = this.sort == 'DESC' ? 'ASC' : 'DESC'
    }


    this.getprojectlist(this.pageSize, this.page_num, this.index);
    this.getcount();
  }


  ngAfterViewInit() {
    setTimeout(() => {
      var formvl = this.findform;
      $('#datetimepicker1').datepicker({
        format: "dd/mm/yyyy",
        autoclose: true,
      }).on('changeDate', function (ev) {
        var dateObject = $(this).datepicker("getDate");
        var d = dateObject.getDate();
        if (dateObject.getDate() < 10) {
          d = '0' + dateObject.getDate();
        }
        var dateString = dateObject.getFullYear() + '-' + (dateObject.getMonth() + 1) + '-' + d;
        formvl.controls['from_date'].setValue(dateString);
        $(this).datepicker('hide');
      });
      $('#datetimepicker2').datepicker({
        format: "dd/mm/yyyy",
        autoclose: true,
        gotoCurrent: true,
        maxDate: "+1d",
      }).on('changeDate', function (ev) {
        var dateObject = $(this).datepicker("getDate");
        var d = dateObject.getDate();
        if (dateObject.getDate() < 10) {
          d = '0' + dateObject.getDate();
        }
        var dateString = dateObject.getFullYear() + '-' + (dateObject.getMonth() + 1) + '-' + d;
        formvl.controls['to_date'].setValue(dateString);
        $(this).datepicker('hide');
      });
    }, 2000);
  }

  createForm() {
    this.findform = new FormGroup({
      "project_title": new FormControl(""),
      "project_logline": new FormControl(""),
      "challenge_status": new FormControl(""),
      "pageSize": new FormControl(""),
      "page_num": new FormControl(""),
      "is_active": new FormControl(""),
      "from_date": new FormControl(""),
      "to_date": new FormControl(""),
      "index": new FormControl(""),
      "actiontype": new FormControl(""),

    });
  }

  projectformreset() {
    this.findform.reset();
    this.findform.controls['pageSize'].setValue(this.pageSize);
    this.findform.controls['page_num'].setValue(this.page_num);
    this.findform.controls['index'].setValue(this.index);
    this.getprojectlist(this.pageSize, this.page_num, this.index);
    this.getcount();
  }

  getprojectlist(pageSize, page_num, index) {
    this.findform.controls['pageSize'].setValue(pageSize);
    this.findform.controls['page_num'].setValue(page_num);
    this.findform.controls['index'].setValue(index);
    this.findform.controls['actiontype'].setValue('list');
    this.findform.value.sortcolumn = this.sortcolumn
    this.findform.value.sort = this.sort
    this.http.post('/admin/all_project_list', this.findform.value, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res['status']) {
          this.projectlist = res['response']

        }
        else if (!res['status']) {
          this.router.navigate(['/dashboard']);
        }
        this.index = res['index']
      }, (err) => {

      });
  }

  getcount() {
    this.findform.controls['actiontype'].setValue('count');
    this.findform.value.sortcolumn = this.sortcolumn
    this.findform.value.sort = this.sort
    this.http.post('/admin/all_project_list', this.findform.value, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res) {
          this.count = res['count'];
        } else {
          this.count = '0';
        }

      }, (err) => {

      });
  }

  activestatus(id) {
    this.id1 = id
  }


  statuschange(id, is_active) {
    this.http.post('/admin/changeuserstatus', { id: id, is_active: is_active }, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res['status']) {
          this.getprojectlist(this.pageSize, this.page_num, this.index);
          this.getcount();
        }
        else if (!res['status']) {
          this.router.navigate(['/dashboard']);
        }

      }, (err) => {

      });
  }

  public exportAsExcelFile(excelFileName: string): void {
    if (this.projectlist.length > 0) {
      var sheet = [];
      for (let index = 0; index < this.projectlist.length; index++) {
        const element = this.projectlist[index];
        var temp = {
          Registered_Date: element.registered_date,
          Email: element.email,
          Phone_Number: element.phone_number,
          Address: element.address,
          User_Name: element.name,
          User_Status: element.status,
          No_of_Projects: element.no_of_projects,
        }
        sheet.push(temp);
      }
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheet);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      for (var C = range.s.r; C <= range.e.r; ++C) {
        var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
        if (!worksheet[address]) continue;
        worksheet[address].v = worksheet[address].v.replace('_', ' ');
      }
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + this.EXCEL_EXTENSION);
  }
}


