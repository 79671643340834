import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from "../../environments/environment";
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  dataLoading: boolean;
  success_message: string;
  error_message: string;

  constructor(private titleService: Title, private http: HttpClient, private router: Router, private route: ActivatedRoute, private cookieService: CookieService, ) { }

  ngOnInit() {
    this.titleService.setTitle('Admin Login- ' + environment.siteTitle);
    this.createForm();
  }

  createForm() {
    this.form = new FormGroup({
      "password": new FormControl("", [Validators.required]),
      "email": new FormControl("", Validators.compose([
        Validators.required,
        Validators.email,
        this.emailDomainValidator,
        Validators.maxLength(50)
      ])),
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  emailDomainValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != null || control.value.length != 0) {
      let email = control.value;
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase()) ? null : { 'compare': true };
    }
    return null;
  }

  login() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    if (this.form.valid) {
      this.http.post(environment.apiUrl+'admin/login', this.form.value, {
        withCredentials: true,
        headers: new HttpHeaders().append('Content-Type', 'application/json')
      })
        .subscribe(res => {
          if (res) {
            if (res['status'] == true) {
              let id = res['myuser']['_id'];
              this.cookieService.set('admintoken', res['myuser']['_id']);
              this.router.navigate(['/dashboard'], { relativeTo: this.route });
            }
            else if (res['status'] == false) {
              if (res['message']) {
                this.error_message = res['message'];
                this.dataLoading = false;
              }
              else {
                this.dataLoading = false;
              }
            }
          } else {
            this.error_message = "Email or password invalid!";
            this.dataLoading = false;
          }
        }, (err) => {
          this.dataLoading = false;
          this.error_message = "Email or password invalid!";
        }
        );
    } else {
      this.validateAllFormFields(this.form);
      this.dataLoading = false;
    }
  }

}
