import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router, UrlTree } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-add-edit-glossary',
  templateUrl: './add-edit-glossary.component.html',
  styleUrls: ['./add-edit-glossary.component.css']
})
export class AddEditGlossaryComponent implements OnInit {
  templatelist: any;
  url: any;
  title: any;
  ckeConfig: any;
  isuserset: string;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  eventform: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  name = 'ng2-ckeditor';
  movements: any = [];
  log: string = '';
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  imageErr: String;
  imageFile: File;
  imageFileName: any;
  imageArray: Array<String> = [];
  Filetype: Array<String> = [];
  filtyp: any;
  filesToUpload: Array<File> = [];
  event_id: any;
  id1: any;
  ImagePreivew: any = [];
  id: any = 0;
  buttontext: any = 'Add';
  pagetitle: any = 'Add';

  constructor(
    private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private apiService: ApiService,
    private titleService: Title,
    private route: ActivatedRoute,
  ) { 
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
        var operationpermissions = this.navItems.find(elem => elem["id"] == 43);
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
          if (this.url == 'create_glossary' && !this.canadd) {
            this.router.navigate(['/glossary']);
          }

        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })
  }

  ngOnInit() {
    this.titleService.setTitle('Add Glossary- ' + environment.siteTitle);
    this.ckeConfig = {
      allowedContent: true,
      extraPlugins: 'divarea',
      height: "300px",
      width: "100%"
    };
    this.getdetail();
    this.createForm()
  }


  onChange1($event: any): void {
  }

  createForm() {
    this.eventform = new FormGroup({
      "id": new FormControl(0),
      "event_name": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(500)
      ])),
      "short_description": new FormControl("", Validators.compose([
        Validators.required,
      ])),
      
      "status": new FormControl("", Validators.compose([
        Validators.required
      ])),
    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  resetEvent() {

    this.ImagePreivew = [];

    this.eventform.reset({
      id: 0,
      event_name: '',
      short_description: '',
      status: '',
    });


  }

  saveData() {

    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    let formData = new FormData();



    if (this.eventform.valid) {
      if(this.id == 0){
        formData.append('created_by', this.userID);
        formData.append('event_name', this.eventform.value.event_name);
        formData.append('short_description', this.eventform.value.short_description);
        // formData.append('long_description', text);
        formData.append('status', this.eventform.value.status);
        formData.append('movements_id', '0');
  
        this.http.post('/admin/create_glossary', formData, {
        })
          .subscribe(res => {
  
            if (res['status']) {
              this.success_message = 'Glossary created successfully';
              setTimeout(() => {
                this.dataLoading = false;
                this.router.navigate(['/glossary']);
                this.success_message = '';
              }, 3000);
            }
            else {
              this.error_message = res['message'];
              setTimeout(() => {
                this.error_message = '';
              }, 3000);
              this.dataLoading = false;
            }
          }, (err) => {
  
            this.error_message = "Glossary can't created";
            this.dataLoading = false;
  
          });
      }else{
        formData.append('updated_by', this.userID);
        formData.append('event_id', this.id);
        formData.append('event_name', this.eventform.value.event_name);
        formData.append('short_description', this.eventform.value.short_description);
        formData.append('status', this.eventform.value.status);
  
        this.http.post('/admin/edit_glossary', formData, {
        })
          .subscribe(res => {
            if (res['status']) {
              this.success_message = 'Glossary updated successfully';
              setTimeout(() => {
                this.dataLoading = false;
  
                this.router.navigate(['/glossary']);
                this.success_message = '';
              }, 3000);
            }
            else {
              this.error_message = res['message'];
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "Glossary can't created";
            this.dataLoading = false;
          });
      }
      
    }
    else {

      this.dataLoading = false;
      this.validateAllFormFields(this.eventform);
    }
  }

  getdetail() {
    if (this.route.snapshot.params['id']) {
      this.id = this.route.snapshot.params['id']
      this.buttontext = "Update"
      this.pagetitle = "Edit"
      this.apiService.postData('admin/glossary_detail', { id: this.id })
        .subscribe(res => {

          this.dataLoading = false;
          if (res['status']) {
            this.eventform.controls['event_name'].setValue(res['detail']['event_name']);
            this.eventform.controls['short_description'].setValue(res['detail']['short_description']);
            this.eventform.controls['status'].setValue(res['detail']['status']);
            this.titleService.setTitle('Edit Glossary- ' + environment.siteTitle);
          } else {
            this.error_message = "Something went wrong !!";
            setTimeout(() => {
              this.router.navigate(['/glossary']);
            }, 3000);
          }
        }, (err) => {
      
          this.router.navigate(['/glossary']);
        });
    }
  }

}
