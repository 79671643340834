import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router,UrlTree } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import {Title} from '@angular/platform-browser'; 
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-edit-media',
  templateUrl: './edit-media.component.html',
  styleUrls: ['./edit-media.component.css']
})
export class EditMediaComponent implements OnInit {
  isuserset: string;
  userID: any;
  url: any;
  email: string;
  role: string;
  last_login: string;
  mediaform: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  media_type_id:any;
  image_url_show =  environment.image_url_show;
  src: String = "";
  imagestatus: boolean = false;
  ImagePreivew: any = [];
  imageErr: String;
  imageArray: Array<String> = [];
  Filetype: Array<String> = [];
  media_image: string = "";
  imageFileName: any;
  filtyp: any;
  filesToUpload: Array<File> = [];
  fileToUploadArr: any = {};
  imgUrlArr: any = {};
    constructor(private userService: UserService,private router: Router, private http: HttpClient,private titleService: Title, private route: ActivatedRoute,private apiService: ApiService,) { 
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list =userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url= this.url.split("/")[0];
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
       
        /* code added by dev@av start */
        var usrAccessMenus = JSON.parse(JSON.stringify(userdata['menu_arr']));
        var operationpermissions = usrAccessMenus.find(elem => elem["id"] == '28');
        /* code added by dev@av end */
        if(typeof(operationpermissions)!='undefined'){
          if(!operationpermissions['view']){
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p']; 
          
         if(this.url == 'edit_media' && !this.canedit )
         {
          this.router.navigate(['/manage_media']);
         }
             
        }else{
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list};
    },
      error => {
        this.router.navigate(['/login']);
      })
      if (this.route.snapshot.params['id']) {
        this.media_type_id = this.route.snapshot.params['id'];
      } else {
        this.router.navigate(['/manage_media']);
      }
  }

  ngOnInit() {
 
    this.titleService.setTitle('Edit Media- ' + environment.siteTitle);                       
    this.createForm();
    this.getdetail();
  }

  createForm() {
    this.mediaform = new FormGroup({
      "media_type_name": new FormControl("", Validators.compose([
        Validators.required,    
        Validators.maxLength(100)                   
      ])),
       "media_status": new FormControl("", Validators.compose([
        Validators.required
        ])),
        "media_image": new FormControl("", Validators.compose([
        Validators.required
        ])),
    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  getdetail() {
    this.http.post('/admin/media_detail', { id: this.media_type_id }, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
  
      .subscribe(res => {
        this.dataLoading = false;
        if (res) {
       
          this.mediaform.controls['media_type_name'].setValue(res['resp']['media_type_name']);
          this.mediaform.controls['media_status'].setValue(res['resp']['media_status']);
          this.media_image = res['resp']['media_image_path'];
          if(this.media_image && this.media_image!=null){
            this.mediaform.controls['media_image'].setValue(this.media_image);
          } 
         
        } else {
          this.error_message = "Something went wrong !!";
          setTimeout(() => {
            this.router.navigate(['/manage_media'], { relativeTo: this.route });
          }, 3000);
        }
      }, (err) => {
      
        this.router.navigate(['/manage_media'], { relativeTo: this.route });
      });
  }


  imagesource(url) {
   
    var source = '';
    source = this.image_url_show + url;
    this.src = source;
  }

  onFileSeleted(event,type= 'update',formKey = '') {
    if(type == 'update'){
         if(formKey == ''){
           return false
         }
         const files = event.target.files;
        
         if(files.length > 0){
           const reader = new FileReader();
           this.fileToUploadArr[formKey] = files[0];
           reader.readAsDataURL(files[0]);
           reader.onload = evt => {
           this.imgUrlArr[formKey] = reader.result;
           };
           if (this.fileToUploadArr[formKey]) {
             if (this.fileToUploadArr[formKey].type === 'image/jpeg' ||
               this.fileToUploadArr[formKey].type === 'image/png' ||
               this.fileToUploadArr[formKey].type === 'image/jpg') {
               if (this.fileToUploadArr[formKey].size < 2000000) {      // checking size here - 2MB           
               }
               else {
                 this.imageErr = "File too large to upload(max 2MB)";
               }
             }
             else {
               this.imageErr = "File type should be jpg,jpeg,png";
             }
           }
         }
         else{
           delete this.fileToUploadArr[formKey]
           delete this.imgUrlArr[formKey]
         }
         
       }
       
     }
   
     fileChangeEvent(fileInput: any) {
       this.filesToUpload = <Array<File>>fileInput.target.files;
     } 
   
     resetMediaForm(){
       this.imgUrlArr['editForm'] = '';
       this.media_image ='';
     }
  
 saveData() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    this.mediaform.addControl('updated_by', new FormControl(this.userID, Validators.required));
    this.mediaform.addControl('media_type_id', new FormControl(this.media_type_id, Validators.required));
    let formData = new FormData();
    if (this.mediaform.valid) {
      const files: Array<File> = this.filesToUpload;
      delete this.mediaform.value.media_image;
      for (let key in this.mediaform.value) {
        formData.append(key, this.mediaform.value[key]);
        
      }

      if(files.length>0){
        for (var i = 0; i < files.length; i++) {
        
          formData.append("media_image_path", files[i], files[i]['name']);
        }
      }
      else{
      
          formData.append('media_image_path',this.media_image);        
          }
      this.http.post('/admin/edit_media',formData)
        .subscribe(res => {
          if (res['status']) {
            this.success_message = 'Media updated successfully';
            setTimeout(() => {
              this.dataLoading = false;

              this.router.navigate(['/manage_media']);
              this.success_message = '';
            }, 3000);
          }
          else{
            this.error_message = res['message'];
            this.dataLoading = false;
          }
        }, (err) => {
          this.error_message = "Media can't updated";
          this.dataLoading = false;
        });
    } else {
      this.dataLoading = false;
      this.validateAllFormFields(this.mediaform);
    }
  }

}










