import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router, UrlTree } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';
declare var $: any

@Component({
  selector: 'app-add-tone-option',
  templateUrl: './add-tone-option.component.html',
  styleUrls: ['./add-tone-option.component.css']
})
export class AddToneOptionComponent implements OnInit {


  templatelist: any;
  url: any;
  title: any;
  title1: any;
  ckeConfig: any;
  isuserset: string;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  toneform: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  name = 'ng2-ckeditor';
  movements: any = [];
  titlelist: any = [];
  log: string = '';
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  imageErr: String;
  imageFile: File;
  imageFileName: any;
  imageArray: Array<String> = [];
  Filetype: Array<String> = [];
  filtyp: any;
  filesToUpload: Array<File> = [];
  event_id: any;
  id1: any;
  id: any
  buttontext: String = 'Add';
  pagetitle: String = 'Add'
  ImagePreivew: any = [];
  imagestatus: boolean = false;
  image_url_show = environment.image_url_show;
  src: String = "";
  image: any = [];
  titlelist1: any = []
  resetimg: any;
  tones_id: any;
  tones_question_id: any = "";
  constructor(private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private apiService: ApiService,
    private titleService: Title,
    private route: ActivatedRoute, ) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];

      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));

        /* code added by dev@av start */
        var usrAccessMenus = JSON.parse(JSON.stringify(userdata['menu_arr']));
        var operationpermissions = usrAccessMenus.find(elem => elem["id"] == '15');
        /* code added by dev@av end */
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
          if (this.url == 'add_tone_option' && !this.canadd) {
            this.router.navigate(['/tone_option']);
          }

        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })

  }

  ngOnInit() {
    this.titleService.setTitle('Add Tone Options- ' + environment.siteTitle);
    this.createForm();
    this.get_title();
    this.getdetail();

  }

  onChange() {
    var tones_id = 0;
    let question = this.titlelist.filter(question => question.id == this.toneform.value.tones_question_id)
    if (question.length > 0) {
      tones_id = question[0].tones_id;
    }
    this.toneform.controls['tones_id'].setValue(tones_id);
  }

  createForm() {
    this.toneform = new FormGroup({
      "option": new FormControl("", Validators.compose([
        Validators.required
      ])),

      "tones_question_id": new FormControl("", Validators.compose([
        Validators.required

      ])),

      "tones_id": new FormControl("", Validators.compose([
        Validators.required

      ])),
      "image": new FormControl("", Validators.compose([
        Validators.required
      ])),
      "is_active": new FormControl("", Validators.compose([
        Validators.required
      ])),
    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  get_title() {
    this.apiService.postData('admin/get_option_tone', {})
      .subscribe(res => {

        if (res['status']) {
          this.titlelist = res['list']

        }
      }, (err) => {

      });
  }

  resettoneoption() {
    this.ImagePreivew = []
  }

  saveData() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    let formData = new FormData();

    if (this.toneform.valid) {
      const files: Array<File> = this.filesToUpload;

      for (let key in this.toneform.value) {
        formData.append(key, this.toneform.value[key]);

      }

      for (var i = 0; i < files.length; i++) {

        formData.append("image", files[i], files[i]['name']);

      }
      this.http.post('/admin/add_tone_option', formData)
        .subscribe(res => {

          if (res['status']) {
            this.success_message = res['message'];
            setTimeout(() => {
              this.dataLoading = false;

              this.router.navigate(['/tone_option']);
              this.success_message = '';
            }, 2000);
          }
          else {
            this.error_message = res['message'];
            this.dataLoading = false;
          }
        }, (err) => {
          this.error_message = "Error occured. please try again.";
          this.dataLoading = false;
          if (err.status == 401) {
            this.router.navigate(['/login']);
          }
        });
    } else {
      this.dataLoading = false;
      this.validateAllFormFields(this.toneform);
    }

  }

  imagesource(url) {
    var source = '';
    source = "assets/img/upload/" + url;
    this.src = source;
  }

  FileSelected(event) {
    this.imagestatus = false;
    this.ImagePreivew = [];
    this.imageErr = "";
    this.Filetype = [];
    this.imageFileName = <File>event.target.files[0].name;
    if (event.target.files[0]) {
      this.filtyp = <File>event.target.files[0].type;
      if (this.filtyp.indexOf("image/jpg") == -1 && this.filtyp.indexOf("image/jpeg") == -1 && this.filtyp.indexOf("image/png")) {

        this.imagestatus = true;
        this.imageErr = "Image(jpg, jpeg, png) file should be accept.";
        return false;
      }
    }

    if (event.target.files[0].size > 2000000) {

      this.imageErr = "No file should be too large to upload(max 2MB)";
      return false;
    }

    var reader = new FileReader();
    reader.onload = (event: any) => {
      if (!this.ImagePreivew.includes(event.target.result)) {
        this.ImagePreivew.push(event.target.result);
      }
    }
    reader.readAsDataURL(event.target.files[0]);
  }

  fileChangeEvent(fileInput: any) {
    this.filesToUpload = <Array<File>>fileInput.target.files;

  }

  getdetail() {
    if (this.route.snapshot.params['id']) {
      this.buttontext = "Update"
      this.pagetitle = "edit"
      this.apiService.postData('admin/tones_option_detail', { id: this.id })
        .subscribe(res => {

          this.dataLoading = false;
          if (res['status']) {
            this.toneform.controls['tones_question_id'].setValue(res['detail']['tones_question_id']);
            this.toneform.controls['option'].setValue(res['detail']['option']);
            this.image = res['detail']['image']
          } else {
            this.error_message = "Something went wrong !!";
            setTimeout(() => {
              this.router.navigate(['/tone_option']);
            }, 3000);
          }
        }, (err) => {

          this.router.navigate(['/tone_option']);
        });
    }
  }

}



















