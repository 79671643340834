import { Component, OnInit, ElementRef } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser'
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  public navItems = [];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  isuserset: string;
  userID: any;
  email1: string;
  role: string;
  last_login: string;
  settingform: FormGroup;
  success_message: String;
  error_message: String;
  imageErr: string = '';
  whitepprimageErr: string = '';
  imageFile: File = null;
  imageFileName: String = '';
  ImagePreivewName: String = '';
  ImagePreivew: String = '';
  imagestatus: boolean = false;
  whitepaperimagestatus: boolean = false;
  whitepaperimageErr: string = '';
  whitepaperimagePreivewName: string = '';
  whitepaperimagePreivew: String = '';
  whitepaperimageFile: File = null;
  whitepaperimageFileName: String = '';
  ext_preview: String;
  white_paper: String;
  site_logo: String;
  ext_cover: String;
  dataLoading: boolean;
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  homeHeaderData = { 'userName': null, 'menu_list': null };
  menu_list: any;
  constructor(private userService: UserService, private router: Router, private http: HttpClient, private route: ActivatedRoute, private el: ElementRef, private titleService: Title) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email1 = userdata['email1'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
        var operationpermissions = this.navItems.find(elem => elem["id"] == '5');

        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.getprofile()
    },
      error => {
        this.router.navigate(['/login']);
      })
  }

  ngOnInit() {
    this.titleService.setTitle('Setting- ' + environment.siteTitle);
    this.createForm()
  }
  onChange($event: any): void {
  }

  getFilePathExtension(path) {
    var filename = path.split('\\').pop().split('/').pop();
    var lower = filename.substr((Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1);
    return lower.toLowerCase();
  }

  createForm() {
    this.settingform = new FormGroup({
      "site_logo": new FormControl(""),
      "email_address": new FormControl("", Validators.compose([
        Validators.required,
        Validators.email,
        this.emailDomainValidator,
        Validators.maxLength(50)
      ])),
      "facebook_link": new FormControl("", Validators.compose([
        Validators.required,
        Validators.pattern(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/),
      ])),
      "google_link": new FormControl("", Validators.compose([
        Validators.required,
        Validators.pattern(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/),
      ])),
      "instagram_link": new FormControl("", Validators.compose([
        Validators.required,
        Validators.pattern(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/),
      ])),
      "twitter_link": new FormControl("", Validators.compose([
        Validators.required,
        Validators.pattern(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/),
      ])),
    })
  }

  emailDomainValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != null) {
      if (control.value.length != 0) {
        let email = control.value;
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase()) ? null : { 'compare': true };
      }
      return null;
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  FileSelected(event) {
    this.imagestatus = false;
    this.imageErr = "";
    this.ImagePreivewName = "";
    this.ImagePreivew = "";
    this.imageFile = <File>event.target.files[0];
    this.imageFileName = this.imageFile.name;
    if (event.target.files && event.target.files[0]) {
      let filtyp = event.target.files[0].type;
      if (filtyp.indexOf("image/jpg") == -1 && filtyp.indexOf("image/jpeg") == -1 && filtyp.indexOf("image/png") == -1) {
        this.settingform.controls['site_logo'].setValue('');
        this.imageErr = "Image(jpg/jpeg/png) file should be accept.";
        this.imagestatus = true;
        return false;
      }
      if (event.target.files[0].size > 2000000) { //2mb
        this.settingform.controls['site_logo'].setValue('');
        this.imageErr = "File is too large to upload(max 2MB)";
        this.imagestatus = true;
        return false;
      }
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.ImagePreivewName = this.imageFile.name;
        this.ImagePreivew = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  settingfunction() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    if (this.settingform.valid) {
      let formData = new FormData();
      let inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#photo');
      let inputE2: HTMLInputElement = this.el.nativeElement.querySelector('#photo1');
      formData.append('email_address', this.settingform.value.email_address);
      formData.append('facebook_link', this.settingform.value.facebook_link);
      formData.append('google_link', this.settingform.value.google_link);
      formData.append('instagram_link', this.settingform.value.instagram_link);
      formData.append('twitter_link', this.settingform.value.twitter_link);
      formData.append('updated_by', this.userID);
      this.http.post('/admin/settings', formData, {
      })
        .subscribe(res => {
          if (res) {
            this.dataLoading = false;
            this.success_message = 'Setting updated successfully';
            setTimeout(() => {
              this.success_message = '';
            }, 2000);
          }
        }, (err) => {
          this.dataLoading = false;
          this.error_message = "Settings not updated";
        });
    } else {
      this.validateAllFormFields(this.settingform);
      this.dataLoading = false;
    }
  }

  getprofile() {
    this.http.post('/admin/setting', { id: this.userID }, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res) {
          this.settingform.controls['email_address'].setValue(res['email_address']);
          this.settingform.controls['facebook_link'].setValue(res['facebook_link']);
          this.settingform.controls['google_link'].setValue(res['google_link']);
          this.settingform.controls['twitter_link'].setValue(res['twitter_link']);
          this.settingform.controls['instagram_link'].setValue(res['instagram_link']);

          this.site_logo = res['site_logo'];
        }
      }, (err) => {

      });
  }
}


