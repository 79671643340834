import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router, UrlTree } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-create-archetype',
  templateUrl: './create-archetype.component.html',
  styleUrls: ['./create-archetype.component.css']
})
export class CreateArchetypeComponent implements OnInit {
  isuserset: string;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  form: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  imageErr: String;
  imageFile: File;
  imageFileName: any;
  imageArray: Array<String> = [];
  Filetype: Array<String> = [];
  ImagePreivew: any = [];
  filtyp: any;
  filesToUpload: Array<File> = [];
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  url: any;
  constructor(private userService: UserService, private router: Router, private http: HttpClient, private apiService: ApiService, private titleService: Title) { 
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];

      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));

        /* code added by dev@av start */
        var usrAccessMenus = JSON.parse(JSON.stringify(userdata['menu_arr']));
        var operationpermissions = usrAccessMenus.find(elem => elem["id"] == '40');
        /* code added by dev@av end */
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
          if (this.url == 'create_archetype' && !this.canadd) {
            this.router.navigate(['/manage_archetype']);
          }

        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })
  }

  ngOnInit() {
    this.titleService.setTitle('Create Archetype- ' + environment.siteTitle);
    this.createForm()
  }

  createForm() {
    this.form = new FormGroup({
      "name": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(100)
      ])),
      "status": new FormControl("", Validators.compose([
        Validators.required
      ])),
      "image_path": new FormControl("", Validators.compose([
        Validators.required
      ])),
    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  FileSelected(event) {

    this.imageErr = "";
    this.imageArray = []
    this.Filetype = [];
    this.ImagePreivew = [];
    if (event.target.files.length <= 5) {
      for (var i = 0; i < event.target.files.length; i++) {
        this.imageFileName = <File>event.target.files[i].name;
        this.imageArray.push(this.imageFileName)

      }
      for (var j = 0; j < event.target.files.length; j++) {
        if (event.target.files && event.target.files[j]) {
          this.filtyp = <File>event.target.files[j].type;

          this.Filetype.push(this.filtyp)
          if (this.Filetype[j].indexOf("image/jpg") == -1 && this.Filetype[j].indexOf("image/jpeg") == -1 && this.Filetype[j].indexOf("image/png")) {

            this.imageErr = "Image(jpg, jpeg, png) file should be accept.";
            return false;
          }
        }

        for (let i = 0; i < this.imageArray.length; i++) {
          var reader = new FileReader();

          reader.onload = (event: any) => {
            if (!this.ImagePreivew.includes(event.target.result)) {
              this.ImagePreivew.push(event.target.result);
            }
          }
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    }
    else {

      this.imageErr = "Number of files should be uploaded 5 or less than 5.";
      return false;
    }
  }

  fileChangeEvent(fileInput: any) {
    this.filesToUpload = <Array<File>>fileInput.target.files;

  }
  resetArchetype() {
    this.ImagePreivew = []
  }

  saveData() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    let formData = new FormData();

    this.form.addControl('id', new FormControl(this.userID, Validators.required));
    if (this.form.valid) {
      const files: Array<File> = this.filesToUpload;


      for (let key in this.form.value) {
        formData.append(key, this.form.value[key]);

      }
      for (var i = 0; i < files.length; i++) {

        formData.append("image_path", files[i], files[i]['name']);
      }

      this.http.post('/admin/create_archetype', formData)
        .subscribe(res => {

          if (res['status']) {
            this.success_message = 'Archetype created successfully';
            setTimeout(() => {
              this.dataLoading = false;

              this.router.navigate(['/manage_archetype']);
              this.success_message = '';
            }, 3000);
          }
          else {
            this.error_message = res['message'];
            this.dataLoading = false;
          }
        }, (err) => {
          this.error_message = "Archetype can't created";
          this.dataLoading = false;
        });
    } else {
      this.dataLoading = false;
      this.validateAllFormFields(this.form);
    }
  }

}
