import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { environment } from "../../environments/environment";
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  success_message: string;
  error_message: string;
  form: FormGroup;
  dataLoading: boolean;
  constructor(private titleService: Title, private router: Router, private route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit() {
    this.titleService.setTitle('Reset Password- ' + environment.siteTitle);
    this.createForm();
  }
  createForm() {
    this.form = new FormGroup({
      "password": new FormControl("", [Validators.required,
      Validators.minLength(6),
      Validators.maxLength(20),
      Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&,.*-]).{6,20}$')
      ]),
      "confirmPassword": new FormControl("", [Validators.required, this.compareValidator('password')]),
      "resetPasswordToken": new FormControl("", [Validators.required])
    });
  }
  compareValidator(controlNameToCompare: string):
    ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (c.value === null || c.value.length === 0) {
        return null;
      }
      const controlToCompare = c.root.get(controlNameToCompare);
      if (controlToCompare) {
        const subscription: Subscription = controlToCompare.valueChanges.subscribe(() => {
          c.updateValueAndValidity(); subscription.unsubscribe();
        });
      }
      return controlToCompare && controlToCompare.value !== c.value ? {
        'compare': true
      } : null;
    };
  }

  resetPassword() {
    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    if (this.form.valid) {
      this.http.post('/admin/reset_password', this.form.value, {
        withCredentials: true,
        headers: new HttpHeaders().append('Content-Type', 'application/json')
      })
        .subscribe(res => {
          if (res) {
            this.success_message = res['message'];
            setTimeout(() => {
              this.router.navigate(['/login']);
            }, 3000);
          } else {
            this.error_message = 'Password reset token is invalid or has expired.';
            this.dataLoading = false;
          }
        }, (err) => {
          this.error_message = 'Password reset token is invalid or has expired.';
          this.dataLoading = false;
        }
        );
    }
    else {
      this.validateAllFormFields(this.form);
      this.dataLoading = false;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}

