import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot, RouterEvent, NavigationStart, RoutesRecognized, NavigationEnd
} from '@angular/router';
import { AuthService } from './auth.service';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/bufferCount';

@Injectable()
export class RoleGuardService implements CanActivate {
    constructor(public auth: AuthService, private router: Router, private http: HttpClient, private cookieService: CookieService) { }

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        // this will be passed from the route config
        // on the data property

        const expectedRole = route.data.expectedRole;
        var page = this;
        return new Promise<boolean>((resolve, reject) => {
            page.auth.isAuthenticated(function (res) {
                if (res['status']) {
                    resolve(true);
                }
                else {
                    page.http.post('/admin/logout', {}, {
                        withCredentials: true,
                        headers: new HttpHeaders().append('Content-Type', 'application/json')
                    })
                        .subscribe(res => {
                            if (res) {
                                page.cookieService.delete('adminemail');
                                page.cookieService.delete('admintoken');
                                page.cookieService.deleteAll();
                                resolve(false);
                                page.router.navigate(['/login']);
                            } else {
                                resolve(false);
                                page.router.navigate(['/login']);
                            }
                        }, (err) => {
                            resolve(false);
                            page.router.navigate(['/login']);
                        });
                }
            })

        });

    }
}