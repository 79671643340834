import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router, UrlTree } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-add-edit-world-segment-options',
  templateUrl: './add-edit-world-segment-options.component.html',
  styleUrls: ['./add-edit-world-segment-options.component.css']
})
export class AddEditWorldSegmentOptionsComponent implements OnInit {

  templatelist: any;
  url: any;
  title: any;
  title1: any;
  ckeConfig: any;
  isuserset: string;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  segmentoptionform: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  name = 'ng2-ckeditor';
  movements: any = [];
  titlelist: any = [];
  log: string = '';
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  imageErr: String;
  imageFile: File;
  imageFileName: any;
  imageArray: Array<String> = [];
  Filetype: Array<String> = [];
  filtyp: any;
  filesToUpload: Array<File> = [];
  event_id: any;
  id1: any;
  id: any
  segmentid: any;
  buttontext: String = 'Add';
  pagetitle: String = 'Add'
  ImagePreivew: any = [];
  sortcolumn: any = '';
  sort: any = 'DESC';
  worldlist: any;
  checkboxval: any;
  public pageSize: number = 10;
  public page_num: number = 1;
  public index: number = 0;

  constructor(private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private apiService: ApiService,
    private titleService: Title,
    private route: ActivatedRoute, ) {
    this.userService.getuser().subscribe(userdata => {

      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];

      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));

        /* code added by dev@av start */
        var usrAccessMenus = JSON.parse(JSON.stringify(userdata['menu_arr']));
        var operationpermissions = usrAccessMenus.find(elem => elem["id"] == '15');
        /* code added by dev@av end */
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
          if (this.url == 'add_World_segment_options' && !this.canadd) {
            this.router.navigate(['/World_segment_options']);
          }
          if (this.url == 'edit_World_segment_options' && !this.canedit) {
            this.router.navigate(['/World_segment_options']);
          }

        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })
    this.id = this.route.snapshot.params['id'];


  }

  ngOnInit() {
    this.titleService.setTitle('Add Segment Options- ' + environment.siteTitle);
    this.createForm()
    this.get_world_title()
    this.getdetail();

  }

  onChange1($event: any): void {
  }

  createForm() {
    this.segmentoptionform = new FormGroup({
      "title": new FormControl("", Validators.compose([Validators.required])),
      "header": new FormControl(""),
      "type": new FormControl("", Validators.compose([Validators.required])),
      "value": new FormControl("0"),
      "placeholder": new FormControl(""),
      "world_id": new FormControl("", Validators.compose([Validators.required])),
      "segment_id": new FormControl("", Validators.compose([Validators.required])),
      "is_active": new FormControl("", Validators.compose([Validators.required])),
      "actiontype": new FormControl(""),
      "pageSize": new FormControl(""),
      "page_num": new FormControl(""),
      "from_date": new FormControl(""),
      "to_date": new FormControl(""),
      "index": new FormControl(""),
      "is_default": new FormControl(1),
    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  segment(event: any, worldId = 0) {

    this.segmentid = worldId != 0 ? worldId : event.target.value;

    this.apiService.postData('admin/world_segments_question_filter', { 'world_id': this.segmentid })
      .subscribe(res => {
        if (res['status']) {
          this.titlelist = res['list']
        }
      }, (err) => {

      });

  }
  placeholder(event: any) {
    this.checkboxval = event.target.value

  }
  formreset() {
    this.segmentoptionform.reset();
    this.segmentoptionform.controls['pageSize'].setValue(this.pageSize);
    this.segmentoptionform.controls['page_num'].setValue(this.page_num);
    this.segmentoptionform.controls['index'].setValue(this.index);
    this.get_world_title();

  }


  get_world_title() {
    this.segmentoptionform.controls['pageSize'].setValue(this.pageSize);
    this.segmentoptionform.controls['page_num'].setValue(this.page_num);
    this.segmentoptionform.controls['actiontype'].setValue('list');
    this.segmentoptionform.value.sortcolumn = this.sortcolumn;
    this.segmentoptionform.value.sort = this.sort;
    this.http.post('/admin/allworld', this.segmentoptionform.value, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {

        if (res['status']) {
          this.worldlist = res['response'];
          this.index = res['index'];

        }
        else if (!res['status']) {
          this.router.navigate(['/dashboard']);
        }
      }, (err) => {

      });
  }





  getdetail() {
    if (this.route.snapshot.params['id']) {
      this.buttontext = "Update"
      this.pagetitle = "Edit"
      this.apiService.postData('admin/world_segments_questions_detail', { id: this.id })
        .subscribe(res => {

          this.dataLoading = false;
          if (res['status']) {
            this.segment(event, res['detail']['world_id'])
            this.segmentoptionform.controls['segment_id'].setValue(res['detail']['segment_id']);
            this.segmentoptionform.controls['world_id'].setValue(res['detail']['world_id']);
            this.segmentoptionform.controls['title'].setValue(res['detail']['title']);
            this.segmentoptionform.controls['header'].setValue(res['detail']['header']);
            this.segmentoptionform.controls['type'].setValue(res['detail']['type']);
            this.segmentoptionform.controls['placeholder'].setValue(res['detail']['placeholder']);
            this.segmentoptionform.controls['is_active'].setValue(res['detail']['is_active']);
            this.titleService.setTitle('Edit Segment Options- ' + environment.siteTitle);
            this.checkboxval = res['detail']['type']

          } else {
            this.error_message = "Something went wrong !!";
            setTimeout(() => {
              this.router.navigate(['/World_segment_options']);
            }, 3000);
          }
        }, (err) => {

          this.router.navigate(['/World_segment_options']);
        });
    }
  }



  saveData() {
    if (this.buttontext == 'Add') {
      this.error_message = "";
      this.success_message = "";
      this.dataLoading = true;
      let formData = new FormData();
      var deafult = this.movements.find(element => element.movements_id == this.segmentoptionform.value.movements_id)

      formData.append('created_by', this.userID);
      formData.append('world_id', this.segmentoptionform.value.world_id);
      formData.append('segment_id', this.segmentoptionform.value.segment_id);
      formData.append('title', this.segmentoptionform.value.title);
      formData.append('header', this.segmentoptionform.value.header);
      formData.append('type', this.segmentoptionform.value.type);
      formData.append('placeholder', this.segmentoptionform.value.placeholder);
      formData.append('is_active', this.segmentoptionform.value.is_active);
      formData.append('is_default', this.segmentoptionform.value.is_default);
      if (this.segmentoptionform.valid) {
        this.http.post('/admin/add_world_segments_questions', formData, {
        })
          .subscribe(res => {

            if (res['status']) {
              this.success_message = 'Segment option added successfully';
              setTimeout(() => {
                this.dataLoading = false;
                this.router.navigate(['/World_segment_options']);
                this.success_message = '';
              }, 3000);
            }
            else {
              this.error_message = "Segment option can't added";
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "Segment option  can't added";
            this.dataLoading = false;
          });
      }
      else {
        this.dataLoading = false;
        this.validateAllFormFields(this.segmentoptionform);
      }
    }
    else {

      this.buttontext == 'Update'
      this.pagetitle == 'Update'
      this.error_message = "";
      this.success_message = "";
      this.dataLoading = true;
      this.segmentoptionform.removeControl('actiontype');
      this.segmentoptionform.removeControl('pageSize');
      this.segmentoptionform.removeControl('page_num');
      this.segmentoptionform.removeControl('from_date');
      this.segmentoptionform.removeControl('to_date');
      this.segmentoptionform.removeControl('index');
      this.segmentoptionform.removeControl('created');

      this.segmentoptionform.addControl('id', new FormControl(this.id, Validators.required));
      if (this.segmentoptionform.valid) {
        this.apiService.postData('admin/update_world_segments_question', this.segmentoptionform.value)
          .subscribe(res => {


            if (res['status']) {
              this.dataLoading = false;

              this.success_message = 'Segment option updated successfully';
              setTimeout(() => {
                this.router.navigate(['/World_segment_options']);
                this.success_message = '';
              }, 3000);
            }
            else {
              this.error_message = " Segment option can't updated";
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "Segment option can't updated";
            this.dataLoading = false;
          });

      }
      else {
        this.dataLoading = false;
        this.validateAllFormFields(this.segmentoptionform);
      }
    }
  }
}




