import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router, UrlTree } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-add-edit-trait',
  templateUrl: './add-edit-trait.component.html',
  styleUrls: ['./add-edit-trait.component.css']
})
export class AddEditTraitComponent implements OnInit {
  isuserset: string;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  traitform: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  buttontext: String = 'Add';
  pagetitle: String = 'Add'
  edit_id: any;
  category: any = [];
  url: any;
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  constructor(private userService: UserService,
    private router: Router,
    private apiService: ApiService,
    private titleService: Title,
    private route: ActivatedRoute, ) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];

      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));

        /* code added by dev@av start */
        var usrAccessMenus = JSON.parse(JSON.stringify(userdata['menu_arr']));
        var operationpermissions = usrAccessMenus.find(elem => elem["id"] == '31');
        /* code added by dev@av end */
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
          if (this.url == 'add_trait' && !this.canadd) {
            this.router.navigate(['/traits']);
          }
          if (this.url == 'edit_trait' && !this.canedit) {
            this.router.navigate(['/traits']);
          }

        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })
    this.edit_id = this.route.snapshot.params['id'];

  }

  ngOnInit() {
    this.titleService.setTitle('Add Traits- ' + environment.siteTitle);
    this.createForm();
    this.get_category()
    this.getdetail();
  }
  createForm() {
    this.traitform = new FormGroup({
      "title": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(100)
      ])),
      "short_description": new FormControl(""),
      "placeholder": new FormControl(""),
      "category_id": new FormControl("", Validators.compose([
        Validators.required
      ])),
      "is_active": new FormControl("", Validators.compose([
        Validators.required
      ])),

    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  get_category() {

    this.apiService.postData('admin/get_category', {})
      .subscribe(res => {

        if (res['status']) {
          this.category = res['list']


        }
      }, (err) => {

        
      });
  }

  getdetail() {
    if (this.route.snapshot.params['id']) {
      this.buttontext = "Update"
      this.pagetitle = "Edit"
      this.apiService.postData('admin/trait_detail', { id: this.edit_id })
        .subscribe(res => {

          this.dataLoading = false;
          if (res['status']) {
            this.traitform.controls['title'].setValue(res['detail']['title']);
            this.traitform.controls['short_description'].setValue(res['detail']['short_description']);
            this.traitform.controls['placeholder'].setValue(res['detail']['placeholder']);
            this.traitform.controls['category_id'].setValue(res['detail']['category_id']);
            this.traitform.controls['is_active'].setValue(res['detail']['is_active']);
            this.titleService.setTitle('Edit Traits- ' + environment.siteTitle);


          } else {
            this.error_message = "Something went wrong !!";

          }
        }, (err) => {
          
          this.router.navigate(['/traits']);
        });
    }
  }
  saveData() {
    if (this.buttontext == 'Add') {
      this.error_message = "";
      this.success_message = "";
      this.dataLoading = true;
      this.traitform.addControl('id', new FormControl(this.userID, Validators.required));
      if (this.traitform.valid) {

        this.apiService.postData('admin/add_trait', this.traitform.value)
          .subscribe(res => {

            if (res['status']) {
              this.success_message = 'Trait added successfully';
              setTimeout(() => {
                this.dataLoading = false;

                this.router.navigate(['/traits']);
                this.success_message = '';
              }, 3000);
            }
            else {
              this.error_message = "Trait can't added";
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "Trait  can't added";
            this.dataLoading = false;
          });
      } else {
        this.dataLoading = false;
        this.validateAllFormFields(this.traitform);
      }
    }

    if (this.buttontext == 'Update') {

      this.error_message = "";
      this.success_message = "";
      this.dataLoading = true;

      this.traitform.addControl('id', new FormControl(this.edit_id, Validators.required));

      if (this.traitform.valid) {
        this.apiService.postData('admin/update_category_traits', this.traitform.value)
          .subscribe(res => {

            if (res['status']) {
              this.success_message = 'Trait updated successfully';
              setTimeout(() => {
                this.dataLoading = false;

                this.router.navigate(['/traits']);
                this.success_message = '';
              }, 3000);
            }
            else {
              this.error_message = "Trait can't updated";
              this.dataLoading = false;
            }
          }, (err) => {
            this.error_message = "Trait can't updated";
            this.dataLoading = false;
          });
      }

      else {
        this.dataLoading = false;
        this.validateAllFormFields(this.traitform);
      }
    }

  }
}

