import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { ManageComponent } from './manage/manage.component';
import { CreateAdminComponent } from './create-admin/create-admin.component';
import { RoleGuardService as RoleGuard } from './role-guard.service';
import { AuthGuard } from './auth-guard.service';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ManageRolesComponent } from './manage-roles/manage-roles.component';
import { CreateRolesComponent } from './create-roles/create-roles.component';
import { EditRolesComponent } from './edit-roles/edit-roles.component';
import { SettingsComponent } from './settings/settings.component';
import { ManageMailsComponent } from './manage-mails/manage-mails.component';
import { ManangeEventComponent } from './manange-event/manange-event.component';
import { CreateEventComponent } from './create-event/create-event.component';
import { EditEventComponent } from './edit-event/edit-event.component';
import { ManageGenreComponent } from './manage-genre/manage-genre.component';
import { CreateGenreComponent } from './create-genre/create-genre.component';
import { EditGenreComponent } from './edit-genre/edit-genre.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { ThemesComponent } from './themes/themes.component';
import { ThemeaddeditComponent } from './themeaddedit/themeaddedit.component';
import { TonesComponent } from './tones/tones.component';
import { ToneaddeditComponent } from './toneaddedit/toneaddedit.component';
import { TonesQuestionsComponent } from './tones-questions/tones-questions.component';
import { AddEditToneQuestionsComponent } from './add-edit-tone-questions/add-edit-tone-questions.component';
import { SceneBuilderComponent } from './scene-builder/scene-builder.component';
import { AddSceneBuilderComponent } from './add-scene-builder/add-scene-builder.component';
import { SceneBuilderDescriptionComponent } from './scene-builder-description/scene-builder-description.component';
import { MovementComponent } from './movement/movement.component';
import { AddEditMovementComponent } from './add-edit-movement/add-edit-movement.component';
import { AddEditThemeQuestionsComponent } from './add-edit-theme-questions/add-edit-theme-questions.component';
import { ThemeQuestionsComponent } from './theme-questions/theme-questions.component';
import { LoglineQuestionComponent } from './logline-question/logline-question.component';
import { AddEditLoglineQuestionComponent } from './add-edit-logline-question/add-edit-logline-question.component';
import { TraitComponent } from './trait/trait.component';
import { AddEditTraitComponent } from './add-edit-trait/add-edit-trait.component';
import { CharacterPropertiesComponent } from './character-properties/character-properties.component';
import { AddEditCharacterPropertiesComponent } from './add-edit-character-properties/add-edit-character-properties.component';
import { WorldComponent } from './world/world.component';
import { AddEditWorldComponent } from './add-edit-world/add-edit-world.component';
import { CharacterCategoryComponent } from './character-category/character-category.component';
import { AddEditCharacterCategoryComponent } from './add-edit-character-category/add-edit-character-category.component';
import { ManageStoriesComponent } from './manage-stories/manage-stories.component';
import { StoryDetailComponent } from './story-detail/story-detail.component';
import { WorldsAspectsComponent } from './worlds-aspects/worlds-aspects.component';
import { AddEditWorldAspectsComponent } from './add-edit-world-aspects/add-edit-world-aspects.component';
import { WorldsSegmentsComponent } from './worlds-segments/worlds-segments.component';
import { AddEditWorldSegmentsComponent } from './add-edit-world-segments/add-edit-world-segments.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { AddEditTutorialComponent } from './add-edit-tutorial/add-edit-tutorial.component';
import { ManageMediaComponent } from './manage-media/manage-media.component';
import { EditMediaComponent } from './edit-media/edit-media.component';
import { CreateMediaComponent } from './create-media/create-media.component';
import { ThemeOptionsComponent } from './theme-options/theme-options.component';
import { EditThemeOptionComponent } from './edit-theme-option/edit-theme-option.component';
import { AddThemeOptionComponent } from './add-theme-option/add-theme-option.component';
import { ToneOptionComponent } from './tone-option/tone-option.component';
import { AddToneOptionComponent } from './add-tone-option/add-tone-option.component';
import { EditToneOptionComponent } from './edit-tone-option/edit-tone-option.component';

import { ProjectPropertiesComponent } from './project-properties/project-properties.component';
import { AddEditProjectPropertiesComponent } from './add-edit-project-properties/add-edit-project-properties.component';
import { WorldSegmentOptionsComponent } from './world-segment-options/world-segment-options.component';
import { AddEditWorldSegmentOptionsComponent } from './add-edit-world-segment-options/add-edit-world-segment-options.component';
import { AddEditProjectGeneralSettingsComponent } from './add-edit-project-general-settings/add-edit-project-general-settings.component';
import { ProjectGeneralSettingsComponent } from './project-general-settings/project-general-settings.component';

import { ManageSidemenuComponent } from './manage-sidemenu/manage-sidemenu.component';
import { EditSidemenuComponent } from './edit-sidemenu/edit-sidemenu.component';
import { ManageArchTypesComponent } from './manage-arch-types/manage-arch-types.component';
import { CreateArchetypeComponent } from './create-archetype/create-archetype.component';
import { EditArchetypeComponent } from './edit-archetype/edit-archetype.component';
import { LoglineDescriptionComponent } from './logline-description/logline-description.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { AddEditGlossaryComponent } from './add-edit-glossary/add-edit-glossary.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { PaymentHistoryDetailComponent } from './payment-history-detail/payment-history-detail.component';
import { SetupPayoffDescriptionComponent } from './setup-payoff-description/setup-payoff-description.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'profile',
    component: AdminProfileComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'forget_password',
    component: ForgetPasswordComponent,
  },
  {
    path: 'reset_password',
    component: ResetPasswordComponent,
    data: { title: 'Reset' }
  },
  {
    path: 'manage_admin',
    component: ManageComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'create_admin',
    component: CreateAdminComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_admin/:id',
    component: CreateAdminComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'manage_roles',
    component: ManageRolesComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'create_roles',
    component: CreateRolesComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_roles/:id',
    component: EditRolesComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'change_pass',
    component: ChangePasswordComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'manage_media',
    component: ManageMediaComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'create_media',
    component:  CreateMediaComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_media/:id',
    component: EditMediaComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'manage_mails',
    component: ManageMailsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'manage_events',
    component: ManangeEventComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'manage_genre',
    component: ManageGenreComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'create_event',
    component: CreateEventComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_event/:id',
    component: EditEventComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'create_genre',
    component: CreateGenreComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_genre/:id',
    component: EditGenreComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'manage_users',
    component: UserManagementComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'user_detail/:id',
    component: UserDetailComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'manage_theme',
    component: ThemesComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'create_theme',
    component: ThemeaddeditComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_theme/:id',
    component: ThemeaddeditComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },

  {
    path: 'add_theme_question',
    component:AddEditThemeQuestionsComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_theme_question/:id',
    component: AddEditThemeQuestionsComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'theme_question',
    component: ThemeQuestionsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },

  {
    path: 'manage_tone',
    component: TonesComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'create_tone',
    component: ToneaddeditComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_tone/:id',
    component: ToneaddeditComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },

  {
    path: 'tone_questions',
    component: TonesQuestionsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'add_tone_questions',
    component:AddEditToneQuestionsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },

  {
    path: 'edit_tone_questions/:id',
    component:AddEditToneQuestionsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },


  {
    path: 'scene_builder_question',
    component: SceneBuilderComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'add_scene_builder',
    component: AddSceneBuilderComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_scene_builder/:id',
    component: AddSceneBuilderComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'scene_builder_description', 
    component: SceneBuilderDescriptionComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'manage_movement',
    component: MovementComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'add_movement',
    component:AddEditMovementComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_movement/:id',
    component:AddEditMovementComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'logline_questions',
    component:  LoglineQuestionComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'add_logline_questions',
    component:  AddEditLoglineQuestionComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_logline_questions/:id',
    component:AddEditLoglineQuestionComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'traits',
    component: TraitComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'add_trait',
    component: AddEditTraitComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_trait/:id',
    component: AddEditTraitComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'character_properties',
    component: CharacterPropertiesComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'add_character_properties',
    component: AddEditCharacterPropertiesComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_character_properties/:id',
    component: AddEditCharacterPropertiesComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'character_category',
    component: CharacterCategoryComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'add_character_category',
    component: AddEditCharacterCategoryComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_character_category/:id',
    component: AddEditCharacterCategoryComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'world',
    component: WorldComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  }, 
  {
    path: 'add_world',
    component: AddEditWorldComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_world/:id',
    component: AddEditWorldComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'worlds_aspects',
    component: WorldsAspectsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'add_world_aspects',
    component: AddEditWorldAspectsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_world_aspects/:id',
    component: AddEditWorldAspectsComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'worlds_segments',
    component: WorldsSegmentsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'add_world_segments',
    component: AddEditWorldSegmentsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_world_segments/:id',
    component: AddEditWorldSegmentsComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'Manage_stories',
    component: ManageStoriesComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'story_detail/:id',
    component: StoryDetailComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'tutorial',
    component:TutorialComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  }, 
  {
    path: 'add_tutorial',
    component: AddEditTutorialComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_tutorial/:id',
    component: AddEditTutorialComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'theme_option',
    component: ThemeOptionsComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'add_theme_option',
    component:  AddThemeOptionComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_theme_option/:id',
    component: EditThemeOptionComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  
  {
    path: 'tone_option',
    component: ToneOptionComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'add_tone_option',
    component:  AddToneOptionComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_tone_option/:id',
    component: EditToneOptionComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  

  {
    path: 'project_properties',
    component: ProjectPropertiesComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'add_project_properties',
    component: AddEditProjectPropertiesComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_project_properties/:id',
    component: AddEditProjectPropertiesComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },

  {
    path: 'World_segment_options',
    component: WorldSegmentOptionsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  
  {
    path: 'add_World_segment_options',
    component: AddEditWorldSegmentOptionsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_World_segment_options/:id',
    component: AddEditWorldSegmentOptionsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'project_general_settings',
    component: ProjectGeneralSettingsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },

  {
    path: 'edit_project_general_settings/:id',
    component: AddEditProjectGeneralSettingsComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'manage_sidemenu',
    component: ManageSidemenuComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  }, 
  {
    path: 'edit_sidemenu/:id',
    component: EditSidemenuComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },

  {
    path: 'manage_archetype',
    component: ManageArchTypesComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'create_archetype',
    component: CreateArchetypeComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_archetype/:id',
    component: EditArchetypeComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },

  {
    path: 'logline_description', 
    component: LoglineDescriptionComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'glossary', 
    component: GlossaryComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'create_glossary', 
    component: AddEditGlossaryComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'edit_glossary/:id', 
    component: AddEditGlossaryComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },

  {
    path: 'payment_history', 
    component: PaymentHistoryComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'payment_history/:userId', 
    component: PaymentHistoryDetailComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },
  {
    path: 'setup_payoff_description', 
    component: SetupPayoffDescriptionComponent ,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  },

  {
    path: '**',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})


export class AppRoutingModule { }



