import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
declare var $: any;

@Component({
  selector: 'app-story-detail',
  templateUrl: './story-detail.component.html',
  styleUrls: ['./story-detail.component.css']
})
export class StoryDetailComponent implements OnInit {
  isuserset: string;
  allLoginDetails: any;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  projectform: FormGroup;
  public pageSize: number = 10;
  public page_num: number = 1;
  public index: number = 0;
  success_message: String;
  error_message: String;
  public navItems = []
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  projectlist: any;
  menu_list: any;
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  detail_id: any;
  count: any;
  medialist: any;
  videolist: any;
  characterlist: any;
  countributorlist: any;
  userdata: any = [];
  html: string = `<span class="btn btn-warning">Never trust not sanitized <code>HTML</code>!!!</span>`;
  EXCEL_EXTENSION = '.xlsx';
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  constructor(private userService: UserService, private router: Router, private http: HttpClient, private titleService: Title, private route: ActivatedRoute, public sanitizer: DomSanitizer) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
        var operationpermissions = this.navItems.find(elem => elem["id"] == 9);
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })
    if (this.route.snapshot.params['id']) {
      this.detail_id = this.route.snapshot.params['id'];

    } else {
      this.router.navigate(['/manage_category']);
    }
  }

  ngOnInit() {
    this.titleService.setTitle('User Detail- ' + environment.siteTitle);
    this.getuserdetail();
    var script = document.createElement('script');
    script.src = '../../assets/js/bootstrap-datepicker.min.js';
    var head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
  }

  getuserdetail() {
    this.http.post('/admin/story_data', { project_id: this.detail_id }, {
      withCredentials: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    })
      .subscribe(res => {
        if (res['status']) {
          this.userdata = res['response']
        }
        else if (!res['status']) {

        }
      }, (err) => {

      });

  }

}