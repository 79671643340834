import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { ActivatedRoute, Route, Router, UrlTree } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReactiveFormsModule, ValidationErrors, FormGroup, FormControl, Validators, FormBuilder, EmailValidator, ValidatorFn, AbstractControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { environment } from "../../environments/environment";
import { ApiService } from '../api.service';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.css']
})
export class CreateEventComponent implements OnInit {
  templatelist: any;
  url: any;
  title: any;
  ckeConfig: any;
  isuserset: string;
  userID: any;
  email: string;
  role: string;
  last_login: string;
  eventform: FormGroup;
  success_message: String;
  error_message: String;
  public navItems = [];
  candelete: boolean = false;
  canedit: boolean = false;
  canadd: boolean = false;
  menu_list: any;
  name = 'ng2-ckeditor';
  movements: any = [];
  log: string = '';
  homeHeaderData = { 'userName': null, 'menu_list': null };
  dataLoading: boolean;
  imageErr: String;
  imageFile: File;
  imageFileName: any;
  imageArray: Array<String> = [];
  Filetype: Array<String> = [];
  filtyp: any;
  filesToUpload: Array<File> = [];
  event_id: any;
  id1: any;
  ImagePreivew: any = [];
  public currentcolor = '#cf2121';
  constructor(private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private apiService: ApiService,
    private titleService: Title) {
    this.userService.getuser().subscribe(userdata => {
      this.userID = userdata['id'];
      this.email = userdata['email'];
      this.isuserset = userdata['fullname'];
      this.role = userdata['type'];
      this.last_login = userdata['last_login'];
      this.menu_list = userdata['menu_list'];
      const tree: UrlTree = router.parseUrl(this.router.url);
      this.url = this.router.url
      this.url = this.url.substring(1);
      this.url = this.url.split("/")[0];
      if (userdata['type'] != 'superadmin') {
        this.navItems = JSON.parse(JSON.stringify(userdata['menu_list']));
        var operationpermissions = this.navItems.find(elem => elem["id"] == 7);
        if (typeof (operationpermissions) != 'undefined') {
          if (!operationpermissions['view']) {
            this.router.navigate(['/dashboard']);
          }
          this.canadd = operationpermissions['add_p'];
          this.canedit = operationpermissions['edit_p'];
          this.candelete = operationpermissions['delete_p'];
          if (this.url == 'create_event' && !this.canadd) {
            this.router.navigate(['/manage_events']);
          }

        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.canadd = true;
        this.canedit = true;
        this.candelete = true;
      }
      this.homeHeaderData = { 'userName': this.isuserset, 'menu_list': this.menu_list };
    },
      error => {
        this.router.navigate(['/login']);
      })
  }

  ngOnInit() {
    this.titleService.setTitle('Manage Events- ' + environment.siteTitle);
    this.ckeConfig = {
      allowedContent: true,
      extraPlugins: 'divarea',
      height: "300px",
      width: "100%"
    };
    this.createForm()
    this.get_movements()

  }

  public onChangeColor(color: string): void {
    this.eventform.get('event_color').setValue(color)
  }
  onChange1($event: any): void {
  }

  createForm() {
    this.eventform = new FormGroup({
      "event_name": new FormControl("", Validators.compose([
        Validators.required,
        Validators.maxLength(500)
      ])),
      "placeholder": new FormControl(""),
      "long_desc_placeholder": new FormControl(""),
      // "short_description": new FormControl("", Validators.compose([
      //   Validators.required,
      //   Validators.maxLength(200)
      // ])),
      // "long_description": new FormControl("", Validators.compose([
      //   Validators.required
      // ])),
      "event_status": new FormControl("", Validators.compose([
        Validators.required
      ])),
      "movements_id": new FormControl("", Validators.compose([
        Validators.required
      ])),
      "movements_title": new FormControl(""),
      "event_color": new FormControl("#cf2121"),

      // "event_image": new FormControl("", Validators.compose([
      //   Validators.required
      // ])),
      "event_video_url": new FormControl("", Validators.compose([
        Validators.maxLength(75),
        Validators.pattern(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/),
      ])),
    })
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  FileSelected(event) {
    this.imageErr = "";
    this.imageArray = []
    this.Filetype = [];
    this.ImagePreivew = [];
    if (event.target.files.length <= 5) {
      for (var i = 0; i < event.target.files.length; i++) {
        this.imageFileName = <File>event.target.files[i].name;
        this.imageArray.push(this.imageFileName)
      }
      for (var j = 0; j < event.target.files.length; j++) {
        if (event.target.files && event.target.files[j]) {
          this.filtyp = <File>event.target.files[j].type;
          this.Filetype.push(this.filtyp)
          if (this.Filetype[j].indexOf("image/jpg") == -1 && this.Filetype[j].indexOf("image/jpeg") == -1 && this.Filetype[j].indexOf("image/png")) {
            this.imageErr = "Image(jpg, jpeg, png) file should be accept.";
            return false;
          }
        }

        for (let i = 0; i < this.imageArray.length; i++) {
          var reader = new FileReader();

          reader.onload = (event: any) => {
            if (!this.ImagePreivew.includes(event.target.result)) {
              this.ImagePreivew.push(event.target.result);
            }
          }
          reader.readAsDataURL(event.target.files[i]);
        }

      }
    }
    else {

      this.imageErr = "Number of files should be uploaded 5 or less than 5.";
      return false;
    }
  }

  fileChangeEvent(fileInput: any) {
    this.filesToUpload = <Array<File>>fileInput.target.files;
  }

  get_movements() {

    this.apiService.postData('admin/get_movements', {})
      .subscribe(res => {

        if (res['status']) {
          this.movements = res['list']
        }
      }, (err) => {

      });
  }

  resetEvent() {

    this.eventform.controls['long_description'].setValue('');

    this.ImagePreivew = [];

    this.eventform.reset({
      event_name: '',
      placeholder: '',
      long_desc_placeholder: '',
      // short_description: '',
      // long_description: '',
      event_status: '',
      movements_id: '',
      movements_title: '',
      // event_image: '',
      event_video_url: '',
    });


  }

  saveData() {

    this.error_message = "";
    this.success_message = "";
    this.dataLoading = true;
    // let formData = new FormData();
    if (this.eventform.valid) {
      var html = this.eventform.value.long_description;
      // html = html.replace(/<[^>]*>/g, '');
      // html = html.replace(/&nbsp;/g, "");
      // var text = html;
      // const files: Array<File> = this.filesToUpload;
      // formData.append('created_by', this.userID);
      // formData.append('event_name', this.eventform.value.event_name);
      // formData.append('placeholder', this.eventform.value.placeholder);
      // formData.append('long_desc_placeholder', this.eventform.value.long_desc_placeholder);
      // formData.append('short_description', this.eventform.value.short_description);
      // formData.append('long_description', text);
      // formData.append('event_status', this.eventform.value.event_status);
      // formData.append('movements_id', this.eventform.value.movements_id);
      // if (this.eventform.value.event_video_url != null) {
      //   formData.append('event_video_url', this.eventform.value.event_video_url);
      // }
      // for (var i = 0; i < files.length; i++) {
      //   formData.append("event_image", files[i], files[i]['name']);
      // }
      var deafult = this.movements.find(element => element.movements_id == this.eventform.value.movements_id)
      this.title = deafult['movements_title']
      // formData.append('movements_title', this.title);

      var formData={
        created_by:this.userID,
        event_name:this.eventform.value.event_name,
        placeholder:this.eventform.value.placeholder,
        event_color:this.eventform.value.event_color,
        long_desc_placeholder:this.eventform.value.long_desc_placeholder,
        event_status:this.eventform.value.event_status,
        movements_id:this.eventform.value.movements_id,
        movements_title:this.title,
      }
      this.http.post('/admin/create_event', formData, {
      })
        .subscribe(res => {

          if (res['status']) {
            this.success_message = 'Event created successfully';
            setTimeout(() => {
              this.dataLoading = false;
              this.router.navigate(['/manage_events']);
              this.success_message = '';
            }, 3000);
          }
          else {
            this.error_message = res['message'];
            setTimeout(() => {
              this.error_message = '';
            }, 3000);
            this.dataLoading = false;
          }
        }, (err) => {

          this.error_message = "Event can't created";
          this.dataLoading = false;

        });
    }
    else {

      this.dataLoading = false;
      this.validateAllFormFields(this.eventform);
    }
  }

}


